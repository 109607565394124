/* eslint-disable max-len */
// 登录流程相关页面配置
export default {
  login: {
    DontHaveAccoun: "Don't have an account?",
    ItSeemsHaven: "It seems you haven't registered.",
    SignUpNow: 'Sign up now',
    SignInNow: 'Sign in now',
    ThisEmailAlready: 'This email has already been taken.',
    ThisPhoneAlready: 'This phone has already been taken.', 
    AlreadyHave: 'Already have an account?',
    CreateAccount: 'Create account',
    FREEEvaluation: 'Sign in to schedule a FREE evaluation',
    CreateANewAccount: 'Create a new account',
    CreateAnAccountTo: 'Schedule a FREE evaluation',
    ContinueWith: 'Continue with',
    policiesTip: 'Please confirm the policies to continue',
    title: 'Sign in',
    confirmTitleForPhone: 'Verify your mobile number',
    confirmTitleForEmail: 'Verify your email address',
    verifyTitle: 'Account security: Please verify your identity',
    verifyTip: 'If you have any questions, please contact us at: ',
    subTitle: 'Don’t have an account? <br>Enter your mobile number to create one.',
    subTitleForEmail: 'Don’t have an account? <br>Enter your email address to create one. ',
    confirmSubTitleForPhone: 'Enter the verification code sent via SMS to:',
    confirmSubTitleForEmail: 'We\'ve sent an email verification code to your inbox: ',
    skipBtn: 'Skip',
    firstLogin: 'Sign in or create an account',
    loginArgain: 'Welcome back',
    loginForm: {
      phone: {
        label: 'Phone number',
        continueBtn: 'Continue with Phone',
        switchBtn: 'Continue with Email',
        placeholder: 'Mobile Phone',
        rules: {
          required: 'The input is required.',
          format: 'Please enter a valid mobile number.',
        },
        verifyMainTitle: 'We sent you a code',
        verifySubTitle: 'Enter it below to verify :',
      },
      captcha: {
        placeholder: 'Verification Code',
        rules: {
          required: 'The input is required.',
          format: 'Incorrect OTP entered.',
        },
      },
      email: {
        label: 'Email Address',
        continueBtn: 'Continue with Email',
        switchBtn: 'Continue with Phone',
        placeholder: 'Email Address',
        rules: {
          required: 'The input is required.',
          format: 'Please enter a valid e-mail.',
        },
        verifyMainTitle: 'We sent you a code',
        verifySubTitle: 'Enter it below to verify :',
      },
      changeTipText: 'or',
      continueBtn: 'Continue',
      submitBtnText: 'Sign in',
      switchEmail: 'Sign in with Email',
      switchPhone: 'Sign in with Phone',
    },
    otpSendBtn: {
      normal: 'Send',
      reResend: 'Resend',
    },
    legalTip: 'Click the check box to register and log in',
    legalCheck: 'By continuing, you acknowledge to be the student\'s parent/legal guardian, agree to Think Academy\'s <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a>, confirm that you have read the <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy</a> and consent to receive marketing and promotional materials.',
    loginFailed: 'login failed!',
    loginSuccess: '',
  },
  switchStudent: {
    choose: 'Choose a Student',
    add: 'Add a New Account',
    addStudent: 'Add Student',
    currentStudent: 'Current Student',
    continueSignUp: 'Continue Sign Up',
    phoneNumTip: 'Your mobile number ({0}){1} is registered to the following student accounts:',
    dialogTip: 'You can book a course for the students below:',
    addTip: ['Registering for a different child not listed above?', ' Click here ', 'to add a new student account under this mobile number and register for them.', 'You can complete the student profile after clicking "Continue Sign Up".'],
    completeStudentProfile: 'Complete Student Profile',
    continueSignUpTip: 'Information in the student profile can help teachers get to know your child. When you\'re ready, click "Continue Sign Up" and proceed to checkout.',
  },
};
