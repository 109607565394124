/* eslint-disable */
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import schools from 'config/schoolsUrl.js';
import bmschools from 'config/bmschoolsUrl.js';
const urlParse = require('url-parse');
const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => { 
  return value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2;
}

/**
 * 获取客户端类型名称
 * Web、Touch、App
 */
export const getClientType = () => {
  const clientType = process.env.clientType
  return clientType.slice(0, 1).toUpperCase() + clientType.slice(1)
}

/**
 * 打印日志
 * @param {日志名称}} logName 
 * @param {日志信息} logInfo 
 */
export const printLog = (logName, logInfo) => {
  console.group(`${logName}: ${new Date()}`);
  console.log(logInfo);
  console.groupEnd();
}

/**
 * 判断元素是否出现可视区
 * @param {元素ID} id
 */
export const isInViewPort = (id) => {
  const el = document.getElementById(id);
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const { offsetTop } = el;
  const scrollTop = document.scrollingElement ? document.scrollingElement.scrollTop : document.documentElement.scrollTop;
  const top = offsetTop - scrollTop;
  const bodyTop = offsetTop - document.body.scrollTop;
  return top <= viewPortHeight || bodyTop <= viewPortHeight;
}

/**
 * 判断元素是否出现可视区-升级版
 * @param {元素ID} id 
 * @returns
 */
 export const isInView = (id) => {
  const ele = document.getElementById(id)
  if (!ele) return false;

  const eleClientRect = ele.getBoundingClientRect()
  // 元素距离浏览器顶部高度
  const eleTop = eleClientRect.top;
  // 元素高度
  const eleHeight = eleClientRect.height
  const halfEleHeight = eleHeight / 2;
  // 视口高度
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  if (eleTop > 0) {
    if (eleTop > viewPortHeight) return false
    if (viewPortHeight - eleTop >= halfEleHeight) return true
    return false
  }
  if (eleTop < 0) {
    if (Math.abs(eleTop) >= eleHeight) return false
    if (eleHeight + eleTop >= halfEleHeight) return true
    return false
  }
  return true
}

/**
 * 判断元素是否出现可视区-touch端也包括判断横向滚动
 * @param {元素ID} id 
 * @returns
 */
 export const isInViewOfAll = (id) => {
  const ele = document.getElementById(id)
  if (!ele) return false;

  const eleClientRect = ele.getBoundingClientRect()
  // 元素距离浏览器顶部高度
  const {
    top,
    left,
    bottom,
    right
  } = eleClientRect
   // 元素高度
   const eleHeight = eleClientRect.height
   const halfEleHeight = eleHeight / 2;
   // 元素宽度
   const eleWidth = eleClientRect.width
   const halfEleWidth = eleWidth / 2;
// 视口高度
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
// 视口宽度
const viewPortWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return top>=(0-halfEleHeight)&&bottom<=(halfEleHeight+viewPortHeight)&&left>=(0-halfEleWidth)&&right<(halfEleWidth+viewPortWidth);
}

/**
 * 返回顶部
 * @param {元素ID} eleID 
 */
export const scrollToTop = () => {
  const beginTime = Date.now();
  const bodyBeginValue = document.body.scrollTop;
  const documentElementBeginValue = document.documentElement.scrollTop;
  const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
  const frameFunc = () => {
    const progress = (Date.now() - beginTime) / 500;
    if (progress < 1) {
      document.body.scrollTop = bodyBeginValue * (1 - easeInOutCubic(progress));
      document.documentElement.scrollTop = documentElementBeginValue * (1 - easeInOutCubic(progress));
      rAF(frameFunc);
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };
  rAF(frameFunc);
}

/**
 * 对象转为数组
 * @param {源对象} object
 */
export const objectToArray = (object) => {
  function* values(object) {
    for (const prop of Object.keys(object)) yield object[prop];
  }

  return Array.from(values(object));
}

export const isTablet = () => {
  if (process.server){ return false }
  let ua = navigator.userAgent,
      isAndroid = /(?:Android)/.test(ua),
      isFireFox = /(?:Firefox)/.test(ua);
  return /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
}

export const isPlayBook = () => {
  if (process.server){ return false }
  let ua = navigator.userAgent,
      isAndroid = /(?:Android)/.test(ua), 
      isFireFox = /(?:Firefox)/.test(ua); 
  return (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)) || (/(?:PlayBook)/.test(ua) ) 
}

export const getSystemVersion = () => {
  if (process.server){ return false }
  let version = '';
  try {
    if (process.env.clientType === 'web') {
      version = 0;
      // ipad、安卓平板 特殊处理
      if (navigator.userAgent.indexOf('Mac') > -1 && !(navigator.userAgent.indexOf('iPad') > -1)) {
        version = navigator.userAgent.split('Mac OS X ')[1].split(')')[0].split('_').join('.')
      } else if (navigator.userAgent.indexOf('OS') > -1 && !(navigator.userAgent.indexOf('Android') > -1)) {
        version = navigator.userAgent.split('OS ')[1].split(' ')[0].split('_')[0]
      }
      if (navigator.userAgent.indexOf('Win') > -1) {
        if (navigator.userAgent.toString().split('Windows NT ')[1]) {
          version = navigator.userAgent.toString().split('Windows NT ')[1].split(';')[0].replace(')', '')
        } else {
          version = 0
        }
      }
      if (navigator.userAgent.indexOf('Android') > -1) {
        version = navigator.userAgent.split('Android ')[1].split(';')[0].split('.')[0]
      }
    } else {
      if (navigator.userAgent.indexOf('OS') > -1) {
        version = navigator.userAgent.split('OS ')[1].split(' ')[0].split('_')[0]
      }
      if (navigator.userAgent.indexOf('Android') > -1) {
        version = navigator.userAgent.split('Android ')[1].split(';')[0].split('.')[0]
      }
    }
  } catch(error) {
    console.log(error)
  }
  return version
}
/**
 * 判断设备是否为安卓手机
 */
export const isAndroid = () => {
  if (process.server){ return false; }
  const ua = navigator.userAgent;
  const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; 
  return isAndroid;
}
export const isIos = () => {
  if (process.server){ return false; }
  const ua = navigator.userAgent;
  const isIos = ua.indexOf('OS') > -1
  return isIos;
}

/**
 * 获取随机数
 * @param {start} start
 * @param {end} end
 */
export const getRandomNumber = (start = 0, end = 0) => {
  if (start == end) { return start; }
  if (start > end) {
    start += end;
    end = start - end;
    start -= end;
  }
  const random = Math.floor(Math.random() * (end - start) + start);
  return random;
}

/**
 * 遍历对象赋值
 * @param {源对象} sourceObj
 * @param {目标对象} targetObj
 */

export const objectAssignment = (sourceObj, targetObj) => {
  if (sourceObj == {}) { return targetObj };

  Object.getOwnPropertyNames(targetObj).forEach((key) => {
    if (key in sourceObj && sourceObj[key] != '') {
      targetObj[key] = sourceObj[key];
    }
  });

  return targetObj;
}

/**
 * 如果target(也就是sourceObj[key])存在，
 * 且是对象的话再去调用deepObjectMerge，
 * 否则就是sourceObj[key]里面没这个对象，需要与targetObj[key]合并
 */
export const deepObjectMerge = (sourceObj, targetObj) => { // 深度合并对象
  for (const key in targetObj) {
    sourceObj[key] = sourceObj[key] && sourceObj[key].toString() === '[object Object]'
      ? deepObjectMerge(sourceObj[key], targetObj[key]) : sourceObj[key] = targetObj[key];
  }
  return sourceObj;
}

/**
 * 处理url的search
 */
export const searchParse = () => {
  const resultObj = {};
  var { search } = window.location;
  if (search && search.length > 1) {
    var search = search.substring(1);
    const items = search.split('&');
    for (let index = 0; index < items.length; index++) {
      if (!items[index]) {
        continue;
      }
      const kv = items[index].split('=');
      resultObj[kv[0]] = typeof kv[1] === 'undefined' ? '' : kv[1];
    }
  }
  return resultObj;
}

/**
 * 判断电脑系统类型 mac or win
 */

export const getSystemType = () => {
  const agent = navigator.userAgent.toLowerCase();
  const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  let type = isMac ? 'mac' : 'other'
  if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
    type = 'win32'
  }
  if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
    type = 'win64'
  }
  return type
}

export const getClientDeviceType = () => {
  const agent = navigator.userAgent.toLowerCase();
  let type = 'other';

  if (/macintosh|mac os x/i.test(agent)) {
    type = 'mac';
  } else if (/win32|wow32/i.test(agent)) {
    type = 'win32';
  } else if (/win64|wow64/i.test(agent)) {
    type = 'win64';
  } else if (/iphone/i.test(agent)) {
    type = 'iphone';
  } else if (/ipad/i.test(agent)) {
    type = 'ipad';
  } else if (/android|tablet/i.test(agent)){
    type='android';
  }

  return type;
}

/** 加密手机号
 * @param {phone}} phone
 * */

 export const encryptPhone = (phone) => {
  if (!phone || phone == '') {
    return ''
  }
  // 待修改
  const phoneLen = phone.length;
  const length = Math.floor(phoneLen / 3);
  let replaceStr = [];
  replaceStr.length = 2 * length - length + 1 + 1;
  replaceStr = replaceStr.join('*');

  return phone.replace(phone.substring(length, 2 * length + 1), replaceStr);
}
/**  
 * 判断ie版本
 * */
export const IEVersion = ()  =>{
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串 
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器 
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器 
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if(isIE) {
      var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
      if(!reIE.test(userAgent)){
        return -1
      }
      var fIEVersion = parseFloat(RegExp["$1"]);
      if(fIEVersion == 7) {
          return 7;
      } else if(fIEVersion == 8) {
          return 8;
      } else if(fIEVersion == 9) {
          return 9;
      } else if(fIEVersion == 10) {
          return 10;
      } else {
          return 6;//IE版本<=7
      }  
  } else if(isEdge) {
      return 'edge';//edge
  } else if(isIE11) {
      return 11; //IE11 
  }else{
      return -1;//不是ie浏览器
  }
}


export const hisPushState = (context, prefix) => {
  const coursesState = context.$store.state.courses
  window.history.replaceState({}, '', `${prefix || ''}?selectedSubject=${coursesState.selectedSubject}&selectedGrade=${coursesState.selectedGrade}&selectedCourseType=${coursesState.selectedCourseType}&selectedDifficulty=${coursesState.selectedDifficulty}`)
}

/**
 * 查找数组中某个元素，返回该元素
 */
export const elementOfArray = (key, value, list) => {
  if (!Array.isArray(list)) return null;
  if (list.length == 0) return null;
  for (let i = 0; i < list.length; i++) {
    if (list[i][key] == value) {
      return list[i];
    }
  }
  return null;
}

/**
 * 查找数组中某个元素，返回该元素索引
 */
 export const elementIndexOfArray = (key, value, list)=> {
  if (!Array.isArray(list)) return -1;
  if (list.length === 0) return -1;
  for (let i = 0; i < list.length; i++) {
    if (`${list[i][key]}` === `${value}`) {
      return i;
    }
  }
  return -1;
}

/**
 * 禁止页面滚动
 */
export const stopElementScroll  = (isFixed, ele) =>{
  if (!ele) {
    ele = window.document.body;
  }
  let top = 0
  if (isFixed) {
    top = ele.getBoundingClientRect().top
    ele.style.position = 'fixed'
    ele.style.top = top + 'px'
  } else {
    ele.style.position = ''
    ele.style.top = ''

    window.scrollTo(0, top) // 回到原先的top
  }
}

export const getRootDomain = (domain) => {
  let resultDomain = domain
  const reg = /\./g;
  let domainTestResult = resultDomain.match(reg);
  // cookies存储在二级域名上
  if(domainTestResult && domainTestResult.length > 1) {
    const regDomain = /\.[a-zA-Z\d\.]+$/;
    const result = resultDomain.match(regDomain);
    // eslint-disable-next-line prefer-destructuring
    if (result && result.length > 0) resultDomain = result[0];
  }
  return resultDomain
}

/**
 * 设置accessToken
 */
export const setCookies = (tokenName = '_official_token', token) => {
  let domain = window.location.hostname;
  const reg = /\./g;
  let domainTestResult = domain.match(reg);
  // cookies存储在二级域名上
  if(domainTestResult && domainTestResult.length > 1) {
    const regDomain = /\.[a-zA-Z\d\.]+$/;
    const result = domain.match(regDomain);
    // eslint-disable-next-line prefer-destructuring
    if (result && result.length > 0) {
      if (domain.includes('.think-matrix.com')) {
        domain = '.think-matrix.com'
      } else if (domain.includes('.bettermeedu.com')) {
        domain = '.bettermeedu.com'
      } else {
        domain = result[0];
      }
    }
  }
  // 历史原因，设置Cookies之前，先删除原完整域名下的cookies
  Cookies.remove(tokenName);
  // 设置官网的Cookies
  Cookies.set(tokenName, token, { expires: 365, domain });
}

/**
 * 移除Cookies
 */
 export const removeCookies = (tokenName = '_official_token') => {
  let domain = window.location.hostname;
  const reg = /\./g;
  let domainTestResult = domain.match(reg);
  // cookies存储在二级域名上
  if(domainTestResult && domainTestResult.length > 1) {
    const regDomain = /\.[a-zA-Z\d\.]+$/;
    const result = domain.match(regDomain);
    // eslint-disable-next-line prefer-destructuring
    if (result && result.length > 0) {
      if (domain.includes('.think-matrix.com')) {
        domain = '.think-matrix.com'
      } else {
        domain = result[0];
      }
    }
  }
  
  // 清除官网的Cookies
  Cookies.remove(tokenName, {domain});
  Cookies.remove(tokenName);
}


/**
 * 重置url参数
 * 
 */
export const resetUrlParams = (addQuery, isRecover = true) => {
  const currentUrl = urlParse(window.location.href, true)
  const originQurey = isRecover ? currentUrl.query: {}
  const query = Object.assign(originQurey, addQuery)
  const keys = Object.keys(query)
  let result = ''
  keys.forEach((key) => {
    const symbol = result.includes('?') ? '&' : '?'
    result += `${symbol}${key}=${query[key]}`
  })
  window.history.replaceState({}, '', result)
}
/**
 * 过滤空格
 */
 export const filterBlank = (str) => {
   if(typeof str !== 'string') return ''
  return str.replace(/\s/g, '')
}

/**
 * 倒计时格式化
 * @param {number} countdown
 * @returns
 */
 export const getDaysByCountdown = (countdown, isTopUnit) => {
  if (!countdown || countdown < 0) return '00'
  let day = ''
  if (isTopUnit) day = Math.floor(moment.duration(countdown).asDays())
  else day = Math.floor(moment.duration(countdown).days())
  day = (`${day}`).padStart(2, '0')
  return day
}

export const getHoursByCountdown = (countdown, isTopUnit) => {
  if (!countdown || countdown < 0) return '00'
  let hour = ''
  if (isTopUnit) hour = Math.floor(moment.duration(countdown).asHours())
  else hour = moment.duration(countdown).hours()
  hour = (`${hour}`).padStart(2, '0')
  return hour
}

export const getMinsByCountdown = (countdown, isTopUnit) => {
  if (!countdown || countdown < 0) return '00'
  let mins = ''
  if (isTopUnit) mins = Math.floor(moment.duration(countdown).asMinutes())
  else mins = moment.duration(countdown).minutes()
  mins = (`${mins}`).padStart(2, '0')
  return mins
}

export const getSecondsByCountdown = (countdown, isTopUnit) => {
  if (!countdown || countdown < 0) return '00'
  let secs = ''
  if (isTopUnit) secs = Math.floor(moment.duration(countdown).asSeconds())
  else secs = moment.duration(countdown).seconds()
  secs = (`${secs}`).padStart(2, '0')
  return secs
}

export function debounce(fn, delay) {
  let timer
  return function (...args) {
    let context = this
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

export class Storage{
  constructor(name){
      this.name = 'storage';
  }
  //设置缓存
  setItem(params){
      let obj = {
          name:'',
          value:'',
          expires:"",
          startTime:new Date().getTime()
      }
      let options = {}; 
      Object.assign(options,obj,params);
      if(options.expires){ 
          localStorage.setItem(options.name,JSON.stringify(options));
      }else{ 
           let type = Object.prototype.toString.call(options.value); 
          if(Object.prototype.toString.call(options.value) == '[object Object]'){
              options.value = JSON.stringify(options.value);
          }
          if(Object.prototype.toString.call(options.value) == '[object Array]'){
              options.value = JSON.stringify(options.value);
          }
          localStorage.setItem(options.name,options.value);
      }
  } 
  getItem(name){
      let item = localStorage.getItem(name); 
      try{
          item = JSON.parse(item);
      }catch(error){ 
          item = item;
      }
      //如果有startTime的值，说明设置了失效时间
      if(item&&item.startTime){
          let date = new Date().getTime(); 
          if(date - item.startTime > item.expires){
          //缓存过期，清除缓存，返回false
              localStorage.removeItem(name);
              return false;
          }else{
          //缓存未过期，返回值
              return item.value;
          }
      }else{ 
          return item;
      }
  } 
  removeItem(name){
      localStorage.removeItem(name);
  } 
  clear(){
      localStorage.clear();
  }
}

export const getOtherSchools = (locale) => {
  if (locale==='bmus' || locale==='bmsg') {
    return bmschools.url.filter(item => item.locale !== locale) 
  } else {
    return schools.url.filter(item => item.locale !== locale) 
  }
}
 
export const s3Download = (signedUrl = '') => {
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.style.height = '0px'
  iframe.setAttribute('src', signedUrl)
  document.body.appendChild(iframe)
  setTimeout(() => {
    iframe.remove()
  }, 1 * 60 * 1000)
}
export const resourceUrl = () => { 
 return process.env.resourceUrl
}

/**
 * 自动加载全部文件
 * @param {*} context require.context
 * @param {*} excludePath 
 * @returns 
 */
export const requireFiles = (context, excludePath = './index.js') => {
  const modules = {}
  context.keys().forEach(path => {
    if (path === excludePath) return
    const key = path.match(/\/(\S+)\.js$/)[1]
    modules[key] = context(path).default
  })
  return modules
}

/**
 * 清除路径末尾斜杠
 * @param {*} path 路径地址
 * @returns 路径地址
 */
export const clearUrlEndSlash = (path) => {
  return path.replace(/([\w\W]+)\/$/, '$1')
}

/**
 * 加载script脚本
 * @param {*} src 文件地址
 * @param {*} options 选项配置
 */
 export const loadScript = (src, options) => {
  if (!src) {
    return
  }
  const { async = false, defer = false } = options
  const d = document
  const s = d.createElement('script')
  s.src = src
  s.async = async
  s.defer = defer
  d.getElementsByTagName('head')[0].appendChild(s)
}

/**
 * 获取指定路径的关闭态
 * @param {*} path 路径
 */
export const getShowSuctionState = (path) => {
  const session = window.sessionStorage
  const closedPath = JSON.parse(session.getItem('closed_path') || '{}')
  return !closedPath[path]
}

/**
 * 设置指定路径的关闭态
 * @param {*} path 路径
 */
export const setShowSuctionState = (path) => {
  const session = window.sessionStorage
  const closedPath = JSON.parse(session.getItem('closed_path') || '{}')
  closedPath[path] = true
  session.setItem('closed_path', JSON.stringify(closedPath))
}
