// 页面配置
export default {
  common: {
    orderStatusText: {
      100: 'To be paid',
      200: 'Paid',
      300: 'Cancelled',
      301: 'Cancelled',
      400: 'Order Refunded',
      500: 'Pending Approval',
    },
    orderStatusDesc: {
      paid: '',
      toBePaid: 'Please complete the payment in {remainTime}',
      refunding: [
        'It can take 7-14 business days for the amount to be reflected on your statement', '', '',
      ],
      refunded: 'Your refund request is now waiting for approval. You\'ll be notified again when your request has been approved.',
    },
    orderBaseInfo: {
      myAccount: 'My Account',
      chooseNewAddress: 'Choose a new address',
      title: 'Order Information',
      orderTime: 'Order Time',
      orderNo: 'Order No.',
      paymentMethod: 'Payment Method',
    },
    refundInfo: {
      title: 'Refund Details',
      nonRefundableItemPrefix: 'Non-refundable {feeName}',
      refundableItemPrefix: 'Refundable {feeName}',
      paymentLabel: 'Payment course fee',
      paymentItemPrefix: 'Payment {feeName}',
      paidClassesLabel: 'Number of paid lesson(s)',
      refundClassLabel: 'Number of refund lesson(s)',
      classesValDesc: '{lesson} lesson(s)',
      refundTotal: 'Refund Total',
      couponTip: 'The coupon will be returned to you after the refund is approved, you can check at your "Student Portal" - "My Coupons".',
      actualPayment: 'Actual payment',
      dialogTitle: 'The purchased recorded course is expired',
      dialogMessage: 'Expired Date: {date} Refund is not applicable. Please contact the support team, should you had any quesiton:',
      refuseApply: 'Sorry, refund for this product is not applicable. Please contact our support team, should you had any question: 【{0}】',
      gotIt: 'Got it',
    },
    errorTip: {
      paidUrlError: 'The payment link is empty',
    },
    exceptionTip: 'No result found',
    // 支付方式
    paymentMethod: {
      0: 'One-time Payment',
      1: 'Installment Payment',
    },
    otherPayment: 'More payment methods',
    // 扣款方式
    debitMethod: {
      1: {
        name: 'Auto Pay',
        desc: 'We support automatic payment with only stripe payment method.',
      },
      0: {
        name: 'Bill Pay',
        desc: 'We support Alipay, WeChat, Card and Apple Pay.',
      },
    },
  },
  courseDetail: 'Course Detail',
  productDetailTitle: 'Product Detail',
  payment: 'Payment Methods',
  paymentStage: {
    installment: 'Installment Payment',
    oneTiimePayment: 'One-time Payment',
    attachedItemName: {
      1: 'Course Materials',
      2: 'Expired',
      3: 'Paid',
      4: 'To be sold',
      5: 'Course Materials',
      6: 'Paid before transfer',
      7: 'Course Materials',
      8: 'Refunded',
    },
    stageStatus: {
      1: '{1} Course Begins',
      2: 'Expired',
      3: 'Paid',
      4: '{0} Registration Opens',
      5: 'Mid-course sign up',
      6: 'Paid before transfer',
      7: 'To be paid after transfer',
      10: 'Not Selected',
    },
    stageOrder: 'Instalment {0}',
    curriculumOrder: 'Lesson {0}',
    paymentRemind: 'SMS Notification Enabled',
    autoPayInProcess: 'Auto pay in process',
    referencePrice: 'Reference price',
  },
  learningMaterials: 'Study Materials',
  yourAddress: 'Your Address',
  orderSummary: 'Order Summary',
  priceInfo: {
    orderTotal: 'Order Total',
    tax: 'GST',
    coupon: 'Coupon Saving',
    subtotal: 'Subtotal',
    groupBuy: 'Discount amount for activity',
    totalTaxDesc: '(includes {symbol}{taxPrice} {taxName})',
    showOrderPriceAll: 'Check the order details',
    goodsQuantity: '1 item | {num} items',
  },

  orderCreate: {
    goodsQuantityInputLabel: 'Quantity',
    existsCheck: {
      title: 'Unpaid order exists',
      content: 'You have an unpaid order for this course, proceed and complete payment.',
      btnText: 'Pay Now',
    },
    addressCheck: {
      title: 'Please add address',
      content: 'Please add address before proceeding to checkout',
      btnText: 'Done',
    },
    registerCheck: {
      content: 'Please complete the registration information before checkout',
      btn: 'Done',
    },
    addressConfirm: {
      title: 'Your lesson material(s) will be delivered to the following address, please check and confirm:',
      bookGoodsTitle: 'The item(s) will be delivered to the following address, please check and confirm:',
      recordedGoodsTitle: 'The learning material will be delivered to the following address, please check and confirm:',
      change: 'Change',
      confirm: 'Confirm',
    },
    noStore: {
      toast: 'This product is currently out of stock.',
    },
    storeNotEnough: {
      toast: 'The number of items you ordered exceeds the number of items in stock ({store}), please re-enter.',
    },
    showConfirmCheckbox: false,
    checkout: 'Checkout',
    bottomTip: `Self-service purchases are available to individual customers.<br> If you're a business customer and need B2B transactions, please contact 
    <a href="mailto:{contactEmail}">{contactEmail}</a> for more details.</span>`,

  },
  orderResult: {
    courseInformation: 'Course information',
    teacherPrefix: 'Your ',
    copy: 'Copy',
    contactTip: '  If you have any questions, please contact your teacher.',
    successTilte: 'Registration Successful',
    successContent: 'Think Academy will send you an Email regarding classroom access and course materials.',
    enquiriesText: 'For enquiries on your orders, please contact us via ',
    enquiriesText1: '',
    howToScan: 'Scan the QR code to get connected with your tutor on WeChat',
    goodsResult: {
      successNotice: 'Purchase successful.',
      failNotice: 'Purchase failed.',
      failDesc: 'View more services provided by Think Academy.',
      successButton: 'View Order',
      failButton: 'View Our Courses',
      independentBtn: 'Go To Study',
    },
  },
  orderDetail: {
    goodItem: {
      courseFeeLabel: 'Course Fee',
    },
    refundProcessBtn: 'Refund Progress',
    refundBtn: [
      'Think Academy is not suitable for your child? ',
      'Click here',
      ' to apply for a refund.',
    ],
    refundBtnName: 'Apply for a refund',
    addressEditTip: 'Teacher will confirm with you after you update the delivery address',
    cancelBtn: 'Cancel',
    payBtn: 'Pay Now',
    contactUsBtn: 'Contact Us',
    viewCourses: 'Browse Courses',
    viewGroupDetail: 'View group buy details',
    refundRequestBtn: 'Request Refund',
    nonRefundForOvertime: {
      notice: 'Sorry, we don\'t process refunds beyond 30 days old. If you have any questions, please contact us via +65 9105 5348',
      buttonName: 'I got it',
    },
    canNotUpdateAddr: {
      content: 'The delivery address cannot be changed, as your package is in transit now. ',
      subBtnText: 'I got it',
    },
    material: {
      title: {
        1: 'Learning Material',
        2: 'Shipment Tracking',
      },
      newTitle: {
        1: 'Delivery Address',
        2: 'Shipment Tracking',
      },
      viewMore: 'View more',
      viewLess: 'View less',
    },
    logistic: {
      viewDetailBtn: 'View details',
      toBeDelivery: 'Shipping soon',
      multiPackagesLogistic: {
        class: {
          status: 'Multiple Packages',
          tipText: 'You will receive multiple packages for this class. Click "View details" for more information',
        },
        good: {
          status: 'Multiple Packages',
          tipText: 'You will receive multiple packages for this order. Click "View details" for more information',
        },
      },
    },

  },
  orderRefund: {
    instruction: 'The total refund amount is inclusive of GST',
    refundRequestBtn: 'Request Refund',
    refundSubmitSuccess: 'Refund Request Submitted',
    refundReason: {
      title: 'Refund Reasons',
      placeholder: 'Please Select',
    },
    refundAuth: {
      title: 'Identification Verification',
      mobile: 'Mobile Number',
      email: 'Email Address',
      otp: 'OTP',
      otpPlaceholder: 'Enter OTP',
      otpError: 'Incorrect OTP entered.',
      otpSend: 'Send',
      otpResend: 'Resend',
      otpSending: '{resendCountdown}s Resend',
      contactTip: {
        mobile: 'If you need to change your phone number, please contact the customer service at (<a class="contact-email-link" :href="mailto:Customercare@thinkacademy.sg">Customercare@thinkacademy.sg</a>)',
        email: 'If you need to change your email address, please contact the customer service at (<a class="contact-email-link" :href="mailto:Customercare@thinkacademy.sg">Customercare@thinkacademy.sg</a>)',
      },
      checkLegal: [
        'I have read and agreed to the ',
        'Terms of Refund',
      ],
    },
    error: {
      209: {
        title: '',
        content: 'Please return the order details for refund',
        mainBtn: 'return',
        subBtn: 'cancel',
      },
    },
  },
  orderStage: {
    showAllStage: 'View all',
    hideStage: 'Put away',
    stageTotal: '({stageTotal} in total)',
  },
  orderPay: {
    case612: {
      title: 'Reminder',
      content: 'Automatic deduction is not supported temporarily. Please select manual payment.',
      mainBtnText: 'I got it',
    },
  },
};
