/**
 * 用户基础数据查询
 */
export default async function ({
  store, app, req,
}) {
  // 客户端环境不执行
  if (process.client) return;
  if (req.ctx.cookies.get('_official_token')) {
    const { url } = req.ctx
    let externalUserId = ''
    let sourceId = ''
    let corpId = ''
    if (url) {
      const regex = /[?&]external_user_id=([^&#]*)/;
      // 执行正则表达式以找到匹配项
      const match = url.match(regex);
      externalUserId = match ? match[1] : '';

      const regex2 = /[?&]source_id=([^&#]*)/;
      // 执行正则表达式以找到匹配项
      const match2 = url.match(regex2);
      sourceId = match2 ? match2[1] : '';

      const regex3 = /[?&]corp_id=([^&#]*)/;
      // 执行正则表达式以找到匹配项
      const match3 = url.match(regex3);
      corpId = match3 ? match3[1] : '';
    }
    await store.dispatch('ucenter/queryUserBasicInfo', { $axios: app.$axios, externalUserId, sourceId, corpId, userData: { fillInAttention: false } });
  } else {
    store.commit('ucenter/SET_IS_LOGIN', false);
  }
}
