export default {
  ImproveUserInfo: {
    title: '完善重要聯繫方式',
    WeChat: '微信',
    WhatsApp: 'WhatsApp',
    Line: 'Line',
    save: '保存',
    cancel: '取消',
    editWeChat: '編輯 微信',
    editWhatsApp: '編輯 WhatsApp',
    editLine: '編輯 Line',
    contactTip1: '微信/WhatsApp/Line将用于教师与家长沟通学生的学习情况。',
    contactTip2: '',
    contactTip3: '請如實填寫聯繫方式，如聯繫方式缺失，會影響老師向家長及時同步學生的學習情况',
    contactTip4: '',
    minChoose: '至少填寫一個聯繫方式才可保存',
  },
  recordingCard: {
    recordingCourse: '錄播課',
    viewOrder: '查看訂單',
    howToAttendClass: '如何進入課堂',
    cancel: '取消',
    viewCourse: '查看課程',
    viewMoreCourse: '查看更多課程',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: '付款重要提示',
    content: '若您已被扣款，但訂單並不是“已支付“狀態，可能是因為我們還未收到銀行的扣款通知，請您稍後查看。若有任何問題，請聯繫我們客服：',
    confirm: '知道了',
  },
  courseDetail: {
    rescheduled: '已調課',
  },
  guidePopup: {
    // 7诊断预约 9已诊断 11预约诊断报告咨询 13完成诊断报告咨询 27长期班待支付
    7: ' to view appointment records.',
    9: ' to view diagnostic reports.',
    11: ' to view appointment records.',
    13: ' to view recommended courses.',
    27: ' to view recommended courses.',
  },
  scoreQuery: {
    legalCheck: '繼續操作前，您需要確認同意我們的<a class="link-text" href="/terms-of-use" target="_blank">用戶協議</a>和<a class="link-text" href="/privacy-policy" target="_blank">隱私協議。</a>',
    searchError: '請輸入不含標點符號、空格或其他特殊字符的內容。',
    notFoundTip: '未找到匹配的記錄。請檢查您的輸入是否正確。',
    queryResult: '查詢結果',
    share: '下載分享至',
    saveImage: '保持圖片',
    cancel: '取消',
  },
  application: {
    regisFirst: '請先完成報名。',
    registerNow: '開始報名',
    fillInfo: '填寫信息',
    confirmDetail: '確認信息',
    payFee: '支付費用',
    getAdmitCard: '獲取准考證',
    finishRegis: '報名完成',
    editInfo: '修改信息',
    confirmAndPay: '確認並支付',
    confirm: '確認',
    orderSuccessNext: '您已成功下單，請點擊下一步繼續。',
    paymentSuccessNext: '支付成功，請點擊下一步繼續。',
    paymentFailTip: '支付失敗，請重新支付未完成的訂單或取消訂單。',
    name: '姓名',
    grade: '考試組別',
    id: '准考證號',
    examName: '考試名稱',
    time: '時間',
    place: '地點',
    online: '線上',
    price: '價格',
    next: '下一步',
    cancelOrder: '取消訂單',
    retryPayment: '重試支付',
    done: '完成',
    close: '關閉',
  },
}
