/**
 * Facebook 广告投放
 */

export default function ({ app, store }) {
  // eslint-disable-next-line
  app.head.htmlAttrs.id = `style__${store.state.betterme}`;

  if (store.state.betterme == "bettermeedu") {
    // app.head.link.forEach(element => {
    //   if( element.type == 'image/x-icon') {
    //     element.href = 'https://download-pa-s3.thethinkacademy.com/static-pa/project/game/assets/favicon.ico'
    //   }
    // });
    app.head.link.push({
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href:
        store.state.betterme == "bettermeedu"
          ? "https://download-pa-s3.thethinkacademy.com/static-pa/project/game/assets/favicon.ico"
          : "/favicon.ico",
    });
    app.head.meta.push({
      name: "og:image",
      content:
        "https://download-pa-s3.thethinkacademy.com/frontend/2025/02/20/7fguiwsgoj.png",
    });
  } else {
    app.head.link.push({
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.ico",
    });
    app.head.meta.push({
      name: "og:image",
      content:
        "https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/common/brand-logo-square.jpg",
    });
  }

  // 客户端环境不执行
  if (process.client) return;

  if (store.state.locale === "sg") {
    // 新分facebook广告投放
    app.head.meta.push({
      name: "facebook-domain-verification",
      content:
        process.env.clientType === "web"
          ? "wl3x609789xm0nj0jq7xg30e5wsc9b"
          : "dzt9pjcsjb2soei9g26xltyxfryqv6",
    });
  }

  if (store.state.locale === "my") {
    // 新分facebook广告投放
    app.head.meta.push({
      name: "facebook-domain-verification",
      content: "cpjet81tio7x04azyp35io4q7wpp8v",
    });
  }
}
