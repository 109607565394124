export default {
  ImproveUserInfo: {
    title: 'Ajouter des informations de contact importantes',
    WeChat: 'WeChat',
    WhatsApp: 'WhatsApp',
    Line: 'Line',
    save: 'Enregistrer',
    cancel: 'Annuler',
    editWeChat: 'Modifier WeChat',
    editWhatsApp: 'Modifier WhatsApp',
    editLine: 'Modifier Line',
    contactTip1: 'WeChat / WhatsApp / Line sera utilisé par les enseignants pour communiquer avec les parents au sujet des élèves.',
    contactTip2: 'Apprentissage.',
    contactTip3: 'Veuillez remplir les informations de contact de manière véridique. Si les informations de contact sont manquantes, Cela affectera la capacité de l\'enseignant à synchroniser les élèves',
    contactTip4: 'Communiquer en temps opportun la situation d\'apprentissage des élèves aux parents',
    minChoose: 'Veuillez remplir au moins une information de contact avant de sauvegarder',
  },
  recordingCard: {
    recordingCourse: 'Cours enregistrés',
    viewOrder: 'Voir la commande',
    howToAttendClass: 'Comment assister aux cours',
    cancel: 'Annuler',
    viewCourse: 'Voir le cours',
    viewMoreCourse: 'Voir plus de cours',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: 'Rappel important concernant les paiements',
    content: 'Si vous avez été déduit, mais la commande n\'est pas dans le statut "payé", mais la commande n\'est pas dans le statut "payé". A vérifier ultérieurement. Si vous avez des questions, veuillez nous contacter à l\'adresse suivante:',
    confirm: 'J\'ai compris!',
  },
  courseDetail: {
    rescheduled: 'Reporté',
  },
  guidePopup: {
    // 7诊断预约 9已诊断 11预约诊断报告咨询 13完成诊断报告咨询 27长期班待支付
    7: ' to view appointment records.',
    9: ' to view diagnostic reports.',
    11: ' to view appointment records.',
    13: ' to view recommended courses.',
    27: ' to view recommended courses.',
  },
  scoreQuery: {
    legalCheck: 'Avant de continuer, veuillez confirmer que vous avez lu et accepté nos <a class="link-text" href="/terms-of-use" target="_blank">Conditions d\'utilisation</a> et notre <a class="link-text" href="/privacy-policy" target="_blank">Politique de confidentialité.</a>',
    searchError: 'Veuillez entrer du contenu sans signes de ponctuation, espaces ou autres caractères spéciaux.',
    notFoundTip: 'Aucun enregistrement correspondant trouvé. Veuillez vérifier si votre saisie est correcte.',
    queryResult: 'Consultation des résultats',
    share: 'Télécharger et sauvegarder au',
    saveImage: 'Sauvegarder l\'image',
    cancel: 'Annuler',
  },
  application: {
    regisFirst: 'Veuillez d\'abord compléter l\'inscription.',
    registerNow: 'Inscription',
    fillInfo: 'Remplir Infos',
    confirmDetail: 'Confirmer Infos',
    payFee: 'Payer',
    getAdmitCard: 'Obtenir Convoc',
    finishRegis: 'Terminé',
    editInfo: 'Modifier Infos',
    confirmAndPay: 'Confirmer et Payer',
    confirm: 'Confirmer',
    orderSuccessNext: 'Commande réussie, cliquez sur Suivant pour continuer.',
    paymentSuccessNext: 'Paiement réussi, cliquez sur Suivant pour continuer.',
    paymentFailTip: 'Échec du paiement, veuillez repayer la commande incomplète ou l\'annuler.',
    name: 'Nom',
    grade: 'Niveau',
    id: 'Numéro de candidat',
    examName: 'Nom de l\'examen',
    time: 'Heure',
    place: 'Lieu',
    online: 'En ligne',
    price: 'Prix',
    next: 'Suivant',
    cancelOrder: 'Annuler la commande',
    retryPayment: 'Réessayer le paiement',
    done: 'Terminé',
    close: 'Fermer',
  },
}
