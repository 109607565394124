export default {
  // 订阅列表
  subscriptionList: {
    title: '내 구독',
    subscriptionDetail: '구독 상세 정보',
    changeAddress: '주소 변경',
    productType: '제품 유형',
    subscriptionStatusList: [
      {
        value: '',
        label: '전체',
      },
    ],
    noOrders: '이 카테고리에 주문이 없습니다. Think Academy에서 제공하는 더 많은 수업를 찾아보세요.',
    moreCourses: '더 많은 수업 보기',
    noSubscriptionText: '현재 자동 갱신 구독이 없습니다',
    unSubscribeTipsTitle: '구독을 취소하려면 학습 센터에 문의하십시오',
    unSubscribeTipsContent: '구독을 취소하면 다음 달에 요금이 청구되지 않습니다. 기존 주문은 진행 중인 수업에 영향을 미치지 않습니다.',
    autoRenewal: '자동 갱신',
    openRecords: '활성화 기록',
    learnPlayTag: 'play and learn',
    every: '매',
    timeLimit: '기간',
    month: '개월',
    phase: '단계',
    stage: '{num} 단계',
    deductionDate: '공제 날짜',
    deductionAmount: '공제 금액',
    status: '상태',
    statusText: {
      1: '지불 예정',
      2: '지불 완료',
      3: '지불 실패',
    },
    unsubscribe: '구독 취소',
    weekMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    weekDayList: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthsList: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    curriculumSchedule: 'Curriculum schedule',
    today: 'today',
    classBegun: 'started',
    unClassBegun: 'not started',
    noClasses: 'No classes today',
    activedTip: 'Click the check box to activated',
  },
  subscriptionCard: {
    pay: '결제',
    unsuceess: '결제 실패',
    viewOrder: '주문 보기',
    start: '시작 시간',
    end: 'End Time',
    nextPay: '다음 결제 예정일',
    expire: '만료 시간',
    expireDate: '만료일',
    orderTime: '주문 시간',
    statusText: {
      1: '결제 예정',
      2: '활성',
      3: '활성',
      4: '취소됨',
    },
  },
  subscriptionDetail: {
    cancel: '구독 취소',
    cancelReasons: '취소 사유',
    title: '구독 상세 정보',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: '중요한 결제 알림',
    content: '차감이 이루어졌지만 주문이 "결제 완료" 상태가 아닌 경우, 은행의 차감 통지를 아직 받지 못한 것일 수 있습니다. 나중에 확인해주세요. 궁금한 사항이 있으시면 다음으로 연락해주세요:',
    confirm: '알겠습니다!',
  },
  personalInfo: {
    childEdit: '자녀 프로필',
    phone: '휴대폰 번호',
    emailAddress: '이메일 주소',
    manage: '자녀 및 개인 정보 관리',
    manageChild: '자녀 관리',
    edit: '프로필 수정',
  },
  childFilter: {
    child: '어린이',
    type: '유형',
    kids: '어린이',
    save: '저장',
  },
  address: {
    noAddress: '아직 추가된 주소가 없습니다.',
  },
  coupon: {
    title: '나의 쿠폰',
    detail: '쿠폰 상세 정보',
    recommendClass: '추천 드리는 수업',
    testResult: '테스트 결과를 파악하기',
    on: '{feeName} 지불 적용',
    over: '만 {symbol}{price} 원',
    off: '%Off',
    noAvailable: '선택하신 쿠폰을 사용할 수 있는 수업이 없습니다.',
    redeemFailedTips: '할인 코드가 존재하지 않습니다. 다시 시도해 주세요.',
    redeemFailedCodeText: {
      4003: 'Sorry, this event has ended.',
      7003: 'Sorry, this event is for new students only.',
      7004: 'You have already participated in this event.',
      7005: 'You have already participated in this event.',
    },
  },
  courses: {
    title: '수업',
    availableClass: '수업 가능한 과목',
    noCoursesTip: '이 카테고리에는 수업이 없습니다. Think Academy에서 제공하는 다른 수업들을 찾아보세요.',
    moreCourses: '수업 더 보기',
  },
  tips: {
    editSuccess: '학생이 성공적으로 편집되었습니다',
    copyFiled: '복사에 실패하셨습니다',
    copySuccess: '복사에 성공적으로',
    success: '성공적으로',
    redeemSuccess: '교환에 성공하셨습니다',
    addSuccess: '학생이 성공적으로 추가되었습니다',
    login: '다시 로그인해주세요',
    loginTips: '로그인 세션이 만료되었습니다. 계속하려면 다시 로그인하세요',
    cancel: '취소',
    loginAgain: '다시 로그인',
    invalid: '필수 입력 사항입니다.',
    removeWish: '위시리스트에서 삭제됨',
    loading: '로딩 중...',
    deleteSuccess: '삭제 성공',
    addSuccessful: '추가 성공',
    updateSuccess: '업데이트 성공',
    updateFailed: '업데이트 실패. 이전 주소와 동일합니다.',
    viewCourses: '수업 보기',
    emptyTips: '곧 제공될 예정인 수업이 없습니다! Think Academy에서 현재 제공되는 수업을 확인하세요.',
    before: '변경전',
    after: '변경후',
  },
  coursesCardTag: {
    new: 'NEW',
    popular: '인기 ',
    full: '만원',
    current: '진행 중',
    completed: '마감됨',
    refunded: '환불됨',
    playBack: '비디오 수업만',
  },
  getNotifiedConfig: {
    title: '당신의 아이에게 적합한 수업를 찾을 수 없나요?',
    description: '걱정하지 마세요! 고민할 시간을 가지시면서, 이메일을 남겨주시면 문법 및 독립 학교 입학을 위한 입학 지원, 11+ 웨비나 링크 및 추가 연습 자료를 받아보실 수 있습니다.',
    form: {
      grade: '아이의 학년',
      firstName: '아이의 이름',
      lastName: '성',
      email: '이메일',
      platform: '어떻게 알게 되셨나요?',
    },
    notice: '이상의 연락처를 정보 수신에 사용할 것을 동의합니다. ',
    errorNotice: '제출에 실패했습니다. 나중에 다시 시도해주세요.',
    emailError: '유효하지 않은 이메일 주소입니다.',
    phoneError: '유효하지 않은 전화번호입니다.',
    submit: '제출',
    success: 'Think Academy에 관심을 가져주셔서 감사합니다!',
  },
  // Cookies声明
  cookiesStatement: {
    description: '더 나은 서비스를 제공하기 위해 쿠키를 사용합니다. 이 페이지의 어떤 링크를 클릭하면 동의한 것으로 간주됩니다.',
  },
  // cookies policy
  cookiesAccept: {
    title: '쿠키 사용 안내',
    description: `<p>웹사이트에서 사용자 경험을 개인화하기 위해 쿠키를 사용합니다.</p>
    <p>자세한 정보는 <a href="/cookies-policy" target="_blank">쿠키 정책</a>을 참조해주세요.</p>`,
    accept: '동의',
    reject: '거부',
  },
  unpaidCourses: {
    tips1: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">수강신청이 많아 수업전환 마감일이 있습니다.
      다음 기한안에 원하는 수업을 선택하십시오.<span style="color: #3e6eff;">{startTime} - {endTime}</span></p>`,
    tips2: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">수강신청이 많아 수업전환 마감일이 있습니다.
      다음 기한 이후 원하는 수업을 선택하십시오: <span style="color: #3e6eff;">{startTime}</span></p>`,
    tips3: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">수강신청이 많아 수강신청 마감일이 있습니다.
      마감 기한 전에 원하는 수업을 선택하십시오: <span style="color: #3e6eff;">{endTime}</span></p>`,
  },
}
