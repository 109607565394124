export default {
  ImproveUserInfo: {
    title: 'Add important contact information',
    WeChat: 'WeChat',
    WhatsApp: 'WhatsApp',
    Line: 'Line',
    save: 'Save',
    cancel: 'Cancel',
    editWeChat: 'Edit WeChat',
    editWhatsApp: 'Edit WhatsApp',
    editLine: 'Edit Line',
    contactTip1: 'WeChat / WhatsApp / Line will be used for teachers to communicate with parents about students',
    contactTip2: 'learning.',
    contactTip3: 'Please fill in the contact information truthfully. If the contact information is missing, it will affect the teacher to synchronize the students',
    contactTip4: 'learning situation with the parents in time',
    minChoose: 'Fill in at least one contact information before saving',
  },
  recordingCard: {
    recordingCourse: 'Recorded Courses',
    viewOrder: 'View Order',
    howToAttendClass: 'How to Attend Class',
    cancel: 'Cancel',
    viewCourse: 'View Course',
    viewMoreCourse: 'View more courses',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: 'Important Payment Reminder',
    content: 'If you have been deducted, but the order is not in the "paid" status, it may be because we have not received the banks deduction notification yet. Please check later. If you have any questions, please contact us at:',
    confirm: 'Got it!',
  },
  courseDetail: {
    rescheduled: 'Rescheduled',
  },
  guidePopup: {
    // 7诊断预约 9已诊断 11预约诊断报告咨询 13完成诊断报告咨询 27长期班待支付
    7: ' to view appointment records.',
    9: ' to view diagnostic reports.',
    11: ' to view appointment records.',
    13: ' to view recommended courses.',
    27: ' to view recommended courses.',
  },
  scoreQuery: {
    legalCheck: 'By continuing, please confirm that you have read and agree to our <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a> and <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy.</a>',
    searchError: 'Please enter content without punctuation marks, spaces, or other special characters.',
    notFoundTip: 'No matching records found. Please check if your input is correct.',
    queryResult: 'Query Results',
    share: 'Download and Share to',
    saveImage: 'Save Image',
    cancel: 'Cancel',
  },
  application: {
    regisFirst: 'Please complete the registration first.',
    registerNow: 'Register Now',
    fillInfo: 'Fill Information',
    confirmDetail: 'Confirm Details',
    payFee: 'Pay Fee',
    getAdmitCard: 'Get Admit Card',
    finishRegis: 'Finish Registration',
    editInfo: 'Edit Info',
    confirmAndPay: 'Confirm and Pay',
    confirm: 'Confirm',
    orderSuccessNext: 'Order successful, click Next to continue.',
    paymentSuccessNext: 'Payment successful, click Next to continue.',
    paymentFailTip: 'Payment failed, please repay the incomplete order or cancel it.',
    name: 'Name',
    grade: 'Grade',
    id: 'Exam ID',
    examName: 'Exam Name',
    time: 'Time',
    place: 'Location',
    online: 'Online',
    price: 'Price',
    next: 'Next',
    cancelOrder: 'Cancel Order',
    retryPayment: 'Retry Payment',
    done: 'Done',
    close: 'Close',
  },
}
