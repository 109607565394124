/* eslint-disable max-len */
/**
 * faq页面配置
 */
export default {
  title: 'FAQ for Parents',
  content: `
  <h2 style="text-decoration: underline;">Choosing A Programme For Your Child</h2>
  <h2>How are the courses conducted?</h2>
  <p>All our interactive and engaging online live courses are taught by Master Teachers – selected graduates of the world’s top universities such as University of Oxford, Princeton University, NUS & NTU, including past top PSLE scorers, Gifted Education Programme (GEP) students and National and International Math Olympiad Winners, and former MOE Teachers.</p>
  <p>The courses are conducted online via Think Academy’s uniquely effective and interactive online classroom learning platform. The Guiding Teacher for your child will contact you via WhatsApp regarding setup needed and lesson materials ahead of your child’s first lesson.</p>
  <p>Guiding Teachers support your child throughout his or her learning journey, starting with a phone conversation to understand your child’s learning needs, providing timely feedback to your child’s post-lesson homework, regular discussions with you about his or her progress, and being there to assist your child with any questions that they may have.</p>


  <h2>How can I decide which programme is suitable for my child?</h2>
  <p>P1 to P3 Advanced programmes and P4 & P5 Math Olympiad programmes emphasise on beyond-school curriculum learning – 70% Olympiad Math content and 30% MOE Curriculum. These are recommended for children with a strong foundation in Math, who easily grasp school syllabus Math and excel in school. The Advanced programmes develop advanced Math thinking and skills across the school syllabus and Math Olympiad, nurturing their potential to win Math Olympiad winners and achieve top PSLE Math results.</p>
  <p>P1 to P3 Intermediate programmes and P4 & P5 Advanced School Math programmes are focused on MOE curriculum learning while providing exposure to challenging questions – 70% MOE Curriculum, 30% Olympiad Math content. These programmes provide a solid foundation for children to do well in PSLE Math and broaden children’s learning beyond the school syllabus requirements to further develop their logical thinking skills through more challenging questions.</p>
  <p>Your child could also do this <a href='https://bit.ly/2021quizzesforthinkacademy' class="text-blue" target="_blank">online quiz</a> to find a recommended programme level. Your child should try it independently at a convenient time for up to one hour, and without using calculators. Pen/pencil and paper may be used for workings. Upon submission, you will receive a course recommendation.</p>

  <h2>Can I enrol my child for more than one programme within the same level, e.g. both P3 Advanced and P3 Intermediate?</h2>
  <p>Your child may only enrol for one programme per level.</p>

  <h2>My child is an advanced learner, can he or she join programmes of a higher level with older peers instead?</h2>
  <p>Our programmes for each level are designed based on the cognitive development of children at each age. We recommend children to join programmes designed for their level. However, your child could take this <a href='https://bit.ly/2021quizzesforthinkacademy' class="text-blue" target="_blank">online quiz</a> to find a recommended programme level to register based on the outcome – joining the higher of the two levels would be sufficient.</p>

  <h2>Are past lesson videos available?</h2>
  <p>Complimentary playback recordings of live lessons within the period of your child’s enrolment are available for up to 6 months after the live classes have ended.</p>

  <h2 style="text-decoration: underline;">Trial Lessons and Course Registration</h2>

  <h2>Is there a trial lesson available for your Long Term Courses?</h2>
  <p>Free trial classes are available for registration – leave your contact details here to be notified when upcoming free trials are available.</p>
  <p>In addition, the first lesson of our Long Term Courses can be considered a free trial – while full payment for the entire programme is required for course registration, you can receive a full refund if the refund request is made via the  <a href='/personal-information' class="text-blue" target="_blank">Student Portal</a> before the start of your child’s second scheduled live lesson.</p>


  <h2>How will I know if I have successfully paid and registered for a course?</h2>
  <p>Upon successful course registration and completed payment, the screen will indicate ‘Registration Successful’ and you will receive a course registration confirmation email.</p>

  <h2>How can I check my orders and course details?</h2>
  <p>You may check under the <a href='/personal-information' class="text-blue" target="_blank">Student Portal</a>.</p>

  <h2>Can I cancel my course booking or request a refund?</h2>
  <p>We offer a flexible refund policy – although the programme fees are paid in full upon registration, from the second lesson onwards, if you do not wish to continue the programme at any point of time, request a refund for the remaining future sessions via the <a href='/personal-information' class="text-blue" target="_blank">Student Portal</a>.</p>

  <h2>I signed up for the wrong course (e.g. incorrect timing) – what can I do?</h2>
  <p>You may contact your child’s Guiding Teacher or message us via <a href='https://wa.me/message/OYAOJELBALLXB1' class="text-blue" target="_blank">WhatsApp (+65 9105 5348)</a> or WeChat (TALSingapore) for assistance.</p>

  <h2>Who can I contact for assistance?</h2>
  <p>For current students, you can contact your child’s Guiding Teacher.</p>
  <p>Otherwise, please message us via <a href='https://wa.me/message/OYAOJELBALLXB1' class="text-blue" target="_blank">WhatsApp (+65 9105 5348)</a> or WeChat (TALSingapore) for assistance.</p>
  `,
}
