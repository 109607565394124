export default {
  // 订阅列表
  subscriptionList: {
    title: '私の購読',
    subscriptionDetail: '購読詳細',
    changeAddress: '住所を変更',
    productType: '商品種類',
    subscriptionStatusList: [
      {
        value: '',
        label: 'すべて',
      },
    ],
    noOrders: 'このカテゴリの注文はありません。Think Academyが提供する他のコースを見る.',
    moreCourses: 'ほかのコースを見る',
    noSubscriptionText: '現在、自動更新の購読はありません',
    unSubscribeTipsTitle: '購読をキャンセルするには、学習センターにお問い合わせください',
    unSubscribeTipsContent: '購読をキャンセルすると、次月に料金が発生しません。既存の注文は進行中のクラスに影響しません。',
    autoRenewal: '自動更新',
    openRecords: 'アクティベーションレコード',
    learnPlayTag: 'play and learn',
    every: '毎',
    timeLimit: '期間',
    month: 'ヶ月',
    phase: 'フェーズ',
    stage: '{num} ステージ',
    deductionDate: '控除日',
    deductionAmount: '控除額',
    status: 'ステータス',
    statusText: {
      1: '支払い待ち',
      2: '支払い済み',
      3: '支払い失敗',
    },
    unsubscribe: '購読をキャンセル',
    weekMap: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    weekDayList: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthsList: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    curriculumSchedule: 'Curriculum schedule',
    today: 'today',
    classBegun: 'started',
    unClassBegun: 'not started',
    noClasses: 'No classes today',
    activedTip: 'Click the check box to activated',
  },
  subscriptionCard: {
    pay: '支払い',
    unsuceess: '控除なし',
    viewOrder: '注文を見る',
    start: '開始時間',
    end: 'End Time',
    nextPay: '次回支払い予定額',
    expire: '有効期限',
    expireDate: '有効期限',
    orderTime: '注文時間',
    statusText: {
      1: '支払い待ち',
      2: '活動的',
      3: '活動的',
      4: 'キャンセルされました',
    },
  },
  subscriptionDetail: {
    cancel: '購読をキャンセルする',
    cancelReasons: 'キャンセルする理由',
    title: '購読詳細',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: '重要な支払いに関する注意事項',
    content: '引き落としが完了しているにもかかわらず、注文が「支払い済み」になっていない場合は、銀行からの引き落とし通知がまだ届いていない可能性があります。後ほどご確認ください。ご不明な点がございましたら、下記までお問い合わせください。',
    confirm: 'OK',
  },
  personalInfo: {
    childEdit: 'お子様のプロフィール',
    phone: '電話番号',
    emailAddress: 'メールアドレス',
    manage: '子供と個人情報の管理',
    manageChild: '子供の管理',
    edit: '私のプロフィールを編集する',
  },
  childFilter: {
    child: '子供',
    type: 'タイプ',
    kids: '子供',
    save: '保存',
  },
  address: {
    noAddress: 'まだアドレスを追加していません',
  },
  coupon: {
    title: 'クーポン',
    detail: 'クーポン詳細',
    recommendClass: 'おすすめコース',
    testResult: 'テスト結果が納得できる',
    on: '{feeName} が',
    over: '{symbol}{price} 以上で使える',
    off: '%Off',
    noAvailable: '選択したクーポンに適用できるコースはありません。',
    redeemFailedTips: 'プロモコードが存在しません。もう一度入力してください。',
    redeemFailedCodeText: {
      4003: 'Sorry, this event has ended.',
      7003: 'Sorry, this event is for new students only.',
      7004: 'You have already participated in this event.',
      7005: 'You have already participated in this event.',
    },
  },
  courses: {
    title: 'コース',
    availableClass: '受講可能なコース',
    noCoursesTip: 'このカテゴリにコースはありません。Think Academyの他のコースをご覧ください',
    moreCourses: 'ほかのコースを見る',
  },
  tips: {
    editSuccess: '学生編集に成功しました',
    copyFiled: 'コピー失敗',
    copySuccess: 'コピー成功',
    success: '成功',
    redeemSuccess: '交換成功',
    addSuccess: '学生編集に成功しました',
    login: '再ログインお願いします',
    loginTips: 'ログインセッションの有効期限が切れました。続行するには再度ログインしてください。',
    cancel: 'キャンセル',
    loginAgain: '再ログイン',
    invalid: '必須項目',
    removeWish: 'ウィッシュリストから削除',
    loading: 'ロード中...',
    deleteSuccess: '削除に成功しました',
    addSuccessful: '追加に成功しました',
    updateSuccess: '更新に成功しました',
    updateFailed: '更新に失敗しました。前のアドレスと同じです。',
    viewCourses: 'コースを見る',
    emptyTips: 'まもなく利用可能なコースが登場します！現在提供されているThink Academyのコースをご覧ください',
    before: '振替前',
    after: '振替後',
  },
  coursesCardTag: {
    new: 'NEW',
    popular: '人気',
    full: '満員',
    current: '進行',
    completed: '終了',
    refunded: '返金済み',
    playBack: '映像授業のみ',
  },
  getNotifiedConfig: {
    title: 'お子様に適切なコースが見つからない場合は？',
    description: '大丈夫です！考える時間を取る間、メールアドレスを残しておいてください。文法や独立学校入学のための入学サポート、11+ウェビナーへのリンク、さらに練習教材などを受け取ることができます。',
    form: {
      grade: 'お子様の学年',
      firstName: 'お子様の名',
      lastName: '姓',
      email: 'メールアドレス',
      platform: 'どのようにして当サイトを知りましたか？',
    },
    notice: '登録していただくと、最新のニュース、プロモーション、最新情報をお届けします！',
    errorNotice: '送信に失敗しました。後でもう一度お試しください。',
    emailError: 'メールアドレスが無効です。',
    phoneError: '電話番号が無効です。',
    submit: '送信',
    success: 'Think Academyへのご関心ありがとうございます！',
  },
  // Cookies声明
  cookiesStatement: {
    description: 'より良いサービスを提供するために、クッキーを使用しています。このページのいずれかのリンクをクリックすることで、ご同意いただいたものとします。',
  },
  // cookies policy
  cookiesAccept: {
    title: 'クッキーの利用',
    description: `<p>当ウェブサイトでは、クッキーを使用してユーザーエクスペリエンスを個別に設定しています。</p>
    <p>詳細については、<a href="/cookies-policy" target="_blank">クッキーポリシー</a>をご覧ください。</p>`,
    accept: '同意する',
    reject: '拒否する',
  },
  unpaidCourses: {
    tips1: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">コースに申し込む方が多いため、
      クラスの振り替えには締切があります。以下の期間内に希望のクラスを選択してください：<span style="color: #3e6eff;">{startTime} - {endTime}</span></p>`,
    tips2: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">コースに申し込む方が多いため、
      クラスの振り替えには締切があります。以下の時間後に希望のクラスを選択してください： <span style="color: #3e6eff;">{startTime}</span></p>`,
    tips3: `
      <p class="dotted-para" style="margin-left: 10px; margin-bottom: 10px;">コースに申し込む方が多いため、
      クラスの振り替えには締切があります。締切前に希望のクラスを選択してください。締切: <span style="color: #3e6eff;">{endTime}</span></p>`,
  },
}
