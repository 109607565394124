export default {
  refund: {
    payment: '결제',
    refundTuition: '환불 가능한 등록금',
    refundMaterial: '환불 가능한 학습 자료',
    refundMaterialTitle: '환불 가능한 학습 자료',
    refundMaterialTips: '배송되지 않은 학습 자료만 환불 신청이 가능합니다. 궁금한 사항이 있으시면 {x}로 문의해주세요.',
    attachedGood: '첨부 상품',
  },
  preclass: '수업 전 준비',
  preclassTips1: '수업 전 준비를 이해하기 위해 ',
  preclassTips2: '을(를) 다운로드해주세요.',
  safetyTips: '당사의 결제 서비스는 안전하게 인증된 국제 결제 기관에서 제공합니다.',
  activation: 'activation',
  toBeActivation: 'To be activated',
  shoppingCart: {
    myCart: '내 카트',
    totalItems: '총 {x} 목',
    deleteItem: '항목 삭제',
    cancelText: '취소',
    confirmDel: '삭제 확인',
    deleteText: '삭제',
    couponsInvalid: '유효하지 않은 쿠폰',
    payMethod: '이 결제 방법은',
    autoDeduction: '자동공제',
    cartDetails: '장바구니 세부정보',
    addToCart: '장바구니에 추가',
    noData: '아직 데이터가 없습니다',
    confirmDelete: '장바구니에서 삭제하시겠습니까?',
    productName: '제품명',
    amtPayable: '지불 금액',
    discountAmt: '할인 금액',
    amtPaid: '실제 지불 금액',
    offerDetails: '혜택 세부정보',
    changeOffer: '제안 변경',
    additional: '추가',
    cart: '장바구니',
    admissionNotice: '귀하의 자녀가 이 강좌에 등록하려면 먼저 입학 진단을 통과해야 합니다. 기회는 {x}회뿐입니다.',
    cannotCreateOrder: '현재 제품에 대한 주문을 생성할 수 없습니다.',
    opFrequent: '작업이 너무 잦습니다.',
    qtyModifiedSuccess: '수량 수정 완료',
    deleteSuccess: '수량 수정 성공',
    valExceed: '수치는 {x}을 초과할 수 없습니다.',
    valLess: '수치는 {x}보다 작을 수 없습니다.',
    addedSuccess: '추가 성공',
    promoNotice: '현재 수업은 일부 사용자에게만 프로모션을 제공하므로 학생 변경 후 결제 시 최종 가격을 확인하시기 바랍니다.',
    addedToCartSuccess: '장바구니 추가 성공',
    combinedDiscount: '결합 할인',
    mailingNotice: '구매하신 수업(상품)은 우편물이 포함되어 있으니 주소 확인 부탁드립니다:',
    refundNotice: '선택하신 환불수업은 연계상품으로 환불은 다른 수업의 자동납부 기능도 취소되는데 계속 진행되는지 확인 부탁드립니다. ',
  },
  selectedClassDiffers: '선택하신 수업은 원래의 수업 {x} 과 다릅니다. 시간표를 변경하시겠습니까?',
  selectedClass: '선택한 반',
  originalClass: '원래 반',
  grade: '학년',
  semester: '학기',
  subject: '과목',
  classLevel: '수업 수준',
  teachingMethod: '강의 방식',
  confirmClassChange: '수업 변경을 확인하시겠습니까?',
  changeMsg1587: '이미 수업 시작 시간이 임박하여 현재 수업을 조정할 수 없습니다.',
  changeMsg1587Transfer: '이미 수업 시작 시간이 임박하여 반을 옮길 수 없습니다.',
  firstClassTime: '첫 수업 시간',
}
