export default {
  ImproveUserInfo: {
    title: '完善重要联系方式',
    WeChat: '微信',
    WhatsApp: 'WhatsApp',
    Line: 'Line',
    save: '保存',
    cancel: '取消',
    editWeChat: '编辑 微信',
    editWhatsApp: '编辑 WhatsApp',
    editLine: '编辑 Line',
    contactTip1: '微信/WhatsApp/Line将用于教师与家长沟通学生的学习情况。',
    contactTip2: '',
    contactTip3: '请如实填写联系方式，如联系方式缺失，会影响老师向家长及时同步学生的学习情况',
    contactTip4: '',
    minChoose: '至少填写一个联系方式才可保存',
  },
  recordingCard: {
    recordingCourse: '录播课',
    viewOrder: '查看订单',
    howToAttendClass: '如何进入课堂',
    cancel: '取消',
    viewCourse: '查看课程',
    viewMoreCourse: '查看更多课程',
  },
  // 支付失败结果页提示文案
  toBePaidResult: {
    title: '付款重要提示',
    content: '若您已被扣款，但订单并不是“已支付“状态，可能是因为我们还未收到银行的扣款通知，请您稍后查看。若有任何问题，请联系我们客服：',
    confirm: '知道了',
  },
  courseDetail: {
    rescheduled: '已调课',
  },
  guidePopup: {
    // 7诊断预约 9已诊断 11预约诊断报告咨询 13完成诊断报告咨询 27长期班待支付
    7: ' to view appointment records.',
    9: ' to view diagnostic reports.',
    11: ' to view appointment records.',
    13: ' to view recommended courses.',
    27: ' to view recommended courses.',
  },
  scoreQuery: {
    legalCheck: 'By continuing, please confirm that you have read and agree to our <a class="link-text" href="/terms-of-use" target="_blank">Terms of Use</a> and <a class="link-text" href="/privacy-policy" target="_blank">Privacy Policy.</a>',
    searchError: 'Please enter content without punctuation marks, spaces, or other special characters.',
    notFoundTip: 'No matching records found. Please check if your input is correct.',
    queryResult: 'Query Results',
    share: 'Download and Share to',
    saveImage: 'Save Image',
    cancel: 'Cancel',
  },
  application: {
    regisFirst: '请先完成报名。',
    registerNow: '开始报名',
    fillInfo: '填写信息',
    confirmDetail: '确认信息',
    payFee: '支付费用',
    getAdmitCard: '获取准考证',
    finishRegis: '完成报名',
    editInfo: '编辑信息',
    confirmAndPay: '确认并支付',
    confirm: '确认',
    orderSuccessNext: '您已成功下单，请点击下一步继续。',
    paymentSuccessNext: '支付成功，请点击下一步继续。',
    paymentFailTip: '支付失败，请重新支付未完成的订单或取消订单。',
    name: '姓名',
    grade: '年级',
    id: '准考证号',
    examName: '考试名称',
    time: '时间',
    place: '地点',
    online: '线上',
    price: '价格',
    next: '下一步',
    cancelOrder: '取消订单',
    retryPayment: '重试支付',
    done: '完成',
    close: '关闭',
  },
}
