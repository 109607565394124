export default {
  reclass: '课前准备',
  preclassTips1: '请下载',
  preclassTips2: '了解课前准备',
  safetyTips: '我们的支付服务由国际认证的安全支付机构提供',
  activation: '激活',
  toBeActivation: '待激活账号',
  shoppingCart: {
    myCart: '我的购物车',
    totalItems: '总计{x}项',
    deleteItem: '删除宝贝',
    cancelText: '取消',
    confirmDel: '确认删除',
    deleteText: '删除',
    couponsInvalid: '不可用优惠券',
    payMethod: '支付方式为',
    autoDeduction: '自动扣款',
    cartDetails: '购物车详情',
    addToCart: '加入购物车',
    noData: '暂无数据',
    confirmDelete: '确定从购物车中删除吗？',
    productName: '商品名称',
    amtPayable: '应付金额',
    discountAmt: '优惠金额',
    amtPaid: '实付金额',
    offerDetails: '优惠详情',
    changeOffer: '变更优惠',
    additional: '附加',
    cart: '购物车',
    admissionNotice: '您的孩子需要通过入学诊断，然后才能报名参加这个课程，只有 {x} 次机会',
    cannotCreateOrder: '当前商品无法创建订单',
    opFrequent: '操作过于频繁',
    qtyModifiedSuccess: '数量修改成功',
    deleteSuccess: '删除操作成功',
    valExceed: '数值不可超过{x}',
    valLess: '数值不可小于{x}',
    addedSuccess: '添加成功',
    promoNotice: '目前仅对部分用户提供优惠活动，请在结账时确认最终价格',
    addedToCartSuccess: '添加购物车成功',
    combinedDiscount: '联报优惠',
    mailingNotice: '您所购买的课程（商品）含有邮寄物，请确认您的地址：',
    refundNotice: '您所选的退款课程为联报商品，退款会关闭联报中其他课程的自动缴费功能，请确认是否继续？',
  },
  selectedClassDiffers: '您选择的班级与原班{x}不同，请确认您要调课吗？',
  selectedClass: '您选择的班级',
  originalClass: '您的原班',
  grade: '年级',
  semester: '学期',
  subject: '学科',
  classLevel: '班次级别',
  teachingMethod: '授课方式',
  confirmClassChange: '是否确认调课',
  changeMsg1587: '已经临近开课时间，当前课次无法调课 ',
  changeMsg1587Transfer: '已经临近开课时间，无法转班',
  firstClassTime: '首课时间',
}
