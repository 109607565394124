<template>
  <div class="login-form-new-container" :class="`login-${locale}`">
    <div class="sf-box">
      <h3 v-if="isTop === 2" class="login-new-title">
        {{ diagnosis ? $t('login.login.CreateAnAccountTo') : $t('login.login.CreateANewAccount') }}
      </h3>
      <h3 v-if="isTop === 3 && !isSend" class="login-new-title">
        {{
          diagnosis ? $t('login.login.FREEEvaluation') : $t('login.login.title')
        }}
      </h3>

      <div v-if="isWeixin === true && !isSend && step === 1" class="weixin_img">
        <img v-lazy-load data-src="~assets/web/images/login/web-weixin.png" alt="">
      </div>
      <!-- <div
        v-if="isTop === 1 && !isSend"
        class="btn-to-nomorl"
        @click="createAccount"
      >
        {{ $t('login.login.CreateAccount') }}
      </div> -->
      <!-- create 1 注册2 登录 3 -->
      <div
        v-if="isTop === 3 || isTop === 2"
        class="login-new-form new-login-font"
      >
        <!-- 手机号 or 邮箱表单 -->
        <PhoneOrEmailForm
          ref="phoneOrEmailForm"
          :scene="1"
          :is-loging="isLoging"
          :last-modified="lastModified"
          :is-top="isTop"
          :step="step"
          :is-un-sign-up="isUnSignUp"
          :relate-list="relateList"
          :policy-agree-status="policyAgreeStatus"
          cookie-name="login-phone-count"
          @submit="checkVerifyCode"
          @changeType="changeType"
          @titleChange="titleChange"
          @changeAccountSend="changeAccountSend"
          @clickContinue="continueHandle"
          @changeLegalCount="changeLegalCount"
          @toSignIn="toSignIn"
          @toSignLogin="login"
          @toCreateLogin="createAccountLogin"
          @updateRegisterInfo="updateRegisterInfo"
        />
        <!-- 手机号表单 -->
        <!-- <PhoneForm
          v-if="type == 'phone'"
          ref="phoneOrEmailForm"
          :scene="1"
          :is-loging="isLoging"
          :last-modified="lastModified"
          :is-top="isTop"
          cookie-name="login-phone-count"
          @submit="login"
          @switch="switchHandle"
          @titleChange="titleChange"
          @clickContinue="continueHandle"
          @toSignIn="toSignIn"
        /> -->
        <!-- 邮箱表单 -->
        <!-- <EmailForm
          v-if="type == 'email'"
          ref="emailForm"
          :scene="1"
          :is-loging="isLoging"
          :last-modified="lastModified"
          :is-top="isTop"
          cookie-name="login-email-count"
          @submit="login"
          @switch="switchHandle"
          @titleChange="titleChange"
          @clickContinue="continueHandle"
          @toSignIn="toSignIn"
        /> -->
      </div>
      <div v-if="isWeixin === false">
        <div v-if="!isSend" class="line-or">
          or
        </div>
        <div v-if="!isSend" class="dsf-l">
          {{ $t('login.login.ContinueWith') }}
        </div>
        <div v-if="!isSend" class="dsf-box">
          <div class="s s1" @click="dsfLogin('f')" />
          <div class="s s2">
            <div
              v-if="!policyAgreeStatus && !isOrgSchool"
              class="pos-btn"
              @click="dsfLogin('g')"
            />
            <div
              id="g_id_onload"
              data-client_id="610998473435-prh7kh715o3cuushj6d2d1d6ejgl6nrj.apps.googleusercontent.com"
              data-context="signin"
              data-ux_mode="popup"
              :data-login_uri="returnUrl"
              data-auto_prompt="false"
            />
            <div class="cgd" />
          </div>
          <span class="s s3" @click="dsfLogin('a')" />
        </div>
      </div>
    </div>
    <div
      v-show="!isSend && (!isOrgSchool || isTop === 2 || isTop === 3)"
      class="login-policy-checkbox-container new-login-font"
    >
      <LegalStatement
        :value.sync="policyAgreeStatus"
        :content="$t('login.login.legalCheck')"
        :tip-message="$t('login.login.legalTip')"
        :legal-count="legalCount"
      />
      <div v-if="isTop === 2" class="sign-in-n">
        {{ $t('login.login.AlreadyHave') }} <span @click="toSignIn(3)">{{ $t('login.login.title') }}</span>
      </div>
      <div v-if="isTop === 3" class="sign-up-n">
        {{ $t('login.login.DontHaveAccoun') }} <span @click="toSignIn(2)">{{ $t('login.login.SignUpNow') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import bus from 'common/bus';
import { oneApi } from 'config/api';
import { setCookies } from 'common/utils.js';
import LegalStatement from 'components/common/LegalStatement/index.vue';
// import PhoneForm from 'components/login/newLoginForm/newPhoneForm.vue';
import PhoneOrEmailForm from 'components/login/newLoginForm/PhoneOrEmailForm.vue';
// import EmailForm from 'components/login/newLoginForm/newEmailForm.vue';
import {
  getLoginTriggerSource,
  getLoginAccountType,
  getLoginMethod,
} from 'common/login.js';
import { Storage } from 'common/utils';
import dataReport from 'common/dataReport';

export default {
  components: {
    LegalStatement,
    // PhoneForm,
    PhoneOrEmailForm,
    // EmailForm,
  },
  props: {
    successUrl: {
      default: '',
      type: String,
    },
    triggerSrc: {
      default: '',
      type: String,
    },
    loginTriggerSource: {
      default: '',
      type: String,
    },
    isGroup: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    const { locale } = this.$store.state;
    return {
      type: 'email',
      locale,
      policyAgreeStatus: false,
      unpaidList: {},
      isSend: false,
      isLoging: false,
      lastModified: {}, // 用户上次登录的信息
      legalCount: 1,
      isTop: 3,
      step: 1, // 1: 初始 2: 查询账号 3: 发送验证码 4:绑定页面 5:注册成功
      diagnosis: false,
      returnUrl: '',
      isWeixin: false,
      loginInfo: {},
      isUnSignUp: false,
      relateList: [],
    };
  },
  computed: {
    hasUnpaidList() {
      return (
        this.$store.state.ucenter.unpaidList.toBeContinueSku
        || this.$store.state.ucenter.unpaidList.inCartSku
      );
    },
    isOrgSchool() {
      return this.locale === 'am' || this.locale === 'ae';
    },
  },
  watch: {
    step: {
      handler(val) {
        this.$emit('stepChange', val);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // 获取 User Agent
    const userAgent = navigator.userAgent.toLowerCase();
    // 判断是否在微信中打开
    if (userAgent.indexOf('micromessenger') !== -1) {
      this.isWeixin = true;// 当前页面在微信中打开
    } else {
      this.isWeixin = false;// 当前页面不在微信中打开
    }

    this.returnUrl = window.location.origin;
    let lastModified = localStorage.getItem('lastModified');

    if (lastModified) {
      lastModified = JSON.parse(lastModified);
      this.type = lastModified.type;
      this.lastModified = lastModified;
    }

    this.isTop = 3;
    this.$emit('starsChange', 3);
    console.log('this.loginTriggerSource', this.loginTriggerSource);
    if (this.loginTriggerSource === 'down') {
      this.isTop = 2;
      this.$emit('starsChange', 2);
    }
    if (this.loginTriggerSource === 'appointment') {
      this.isTop = 2;
      this.diagnosis = true;
      this.$emit('starsChange', 2);
    }
    this.eventTrack('register_page_pv', { type: this.isTop === 1 ? 'sign-up' : 'sign-in' });
    if (!window.google) return;
    // // 初始化認證登入

    google.accounts.id.initialize({
      client_id:
        '610998473435-prh7kh715o3cuushj6d2d1d6ejgl6nrj.apps.googleusercontent.com',
      callback: this.googleCallBack,
      context: 'signin',
      ux_mode: 'popup',
      login_uri: this.returnUrl,
      auto_prompt: 'false',
      type: 'icon',
    });
    // 渲染按鈕
    google.accounts.id.renderButton(document.getElementById('g_id_onload'), {
      type: 'profile',
    });

    AppleID.auth.init({
      clientId: 'com.tal.hw.stu.signin',
      scope: 'name email',
      redirectURI: this.returnUrl,
      usePopup: true,
    });

    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
      // Handle successful response.
      this.lxToken(3, event.detail.authorization.id_token);
    });

    // Listen for authorization failures.
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      // Handle error.
      console.log(event.detail.error);
    });
  },
  methods: {
    googleCallBack(res) {
      this.lxToken(2, res.credential);
      console.log('谷歌回调', res);
      if (this.isTop == 3) {
        this.Sensors.track('sign_in_click', {
          account_type: 'Google',
          previous_source: '弹窗',
          register_type: '第三方',
        });
      } else {
        this.Sensors.track('sign_up_click', {
          account_type: 'Google',
          previous_source: '弹窗',
          register_type: '第三方',
        });
      }
    },
    eventTrack(eventName, params = {}) {
      this.Sensors.track(eventName, {
        account_type: getLoginAccountType(this.type === 'phone' ? 0 : 3),
        previous_source: getLoginTriggerSource(this.loginTriggerSource),
        ...params,
      });
    },
    // 百度广告投放埋点
    mediagoTrack() {
      // eslint-disable-next-line no-underscore-dangle
      window._megoaa = window._megoaa || [];
      // eslint-disable-next-line no-underscore-dangle
      window._megoaa.push({
        type: 'event',
        name: 'Signup',
        acid: '25112',
        pxd: '781872466259684',
      });
    },
    titleChange(obj) {
      this.isSend = obj.isSend;
      const titleObj = Object.assign(obj, { type: this.type });
      this.$emit('changeTitle', titleObj);
    },
    changeAccountSend(isAccountSend) {
      this.$emit('changeCodeSend', isAccountSend);
    },
    backAccountSend() {
      this.$refs.phoneOrEmailForm.backAccountCodeSend();
    },
    switchHandle() {
      let btnText = this.$t('login.login.loginForm.switchPhone');
      if (this.type === 'phone') {
        this.type = 'email';
        btnText = this.$t('login.login.loginForm.switchEmail');
      } else {
        this.type = 'phone';
        btnText = this.$t('login.login.loginForm.switchPhone');
      }
      this.eventTrack('register_page_pv', { type: this.isTop === 1 ? 'sign-up' : 'sign-in' });
      this.eventTrack('register_page_register_type', {
        button_click: btnText,
      });
      const obj = {
        type: this.type,
        isSend: false,
      };
      this.$emit('changeTitle', obj);
    },
    async getUnpaidList() {
      const res = await this.$axios.post(
        oneApi.unpaidRemind,
        {},
        { rewritePostBody: true },
      );

      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        return;
      }

      const resData = res.data || {};
      this.unpaidList = resData;
      this.$store.commit('ucenter/SET_UNPAID_LIST', resData);
      console.log(this.$store.state.ucenter.unpaidList);
    },
    backFunc() {
      this.$refs.phoneOrEmailForm.init();
      this.isSend = false;
      this.isUnSignUp = false;
      // 修改dialog文案使用
      this.$emit('changeTitle', {
        type: this.type,
        isSend: this.isSend,
      });
    },
    backIndex(num) {
      if (this.step > 1) {
        this.step -= num
      }
      // this.isTop = num;
      this.$emit('starsChange', num);
    },
    continueHandle() {
      this.eventTrack('register_page_register_type', {
        button_click: this.$t('login.login.loginForm.continueBtn'),
      });
    },
    changeType(type) {
      this.type = type;
      console.log('changeType', type);
    },
    changeLegalCount() {
      this.legalCount += 1;
    },
    // 通过验证码修改注册信息
    async updateRegisterInfo(params) {
      console.log('updateRegisterInfo', params);
      const { preData, formData } = params;
      const data = {
        phone: preData?.phoneOrEmail,
        email: preData?.phoneOrEmail,
        verificationCode: preData?.captcha,
        countryCallingCode: preData?.phoneAreaCode?.value,
        targetPhone: formData?.phoneOrEmail,
        targetEmail: formData?.phoneOrEmail,
        targetVerificationCode: formData?.captcha,
        targetCountryCallingCode: formData?.phoneAreaCode?.value,
      }
      // 选择账号类型
      if (preData.selectedAccountType === 'phone') {
        delete data.email;
      } else {
        delete data.phone;
        delete data.countryCallingCode;
      }
      // 输入账号类型
      if (this.type === 'phone') {
        delete data.targetEmail;
      } else {
        delete data.targetPhone;
        delete data.targetCountryCallingCode;
      }
      const res = await this.$axios.post(oneApi.modifyUserInfoByCode, data, { rewritePostBody: true });

      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        return;
      }
      this.step = 4
    },

    async checkVerifyCode(form, isAccountLogin = false) {
      this.loginInfo = form;
      console.log('checkVerifyCode', this.type, form);
      this.isLoging = true;
      let data = {};
      if (this.type === 'phone') {
        data = {
          type: 0,
          contactInfo: form.phoneOrEmail,
          verificationCode: form.captcha,
          countryCallingCode: form.phoneAreaCode.value,
        };
      } else {
        data = {
          type: 2,
          contactInfo: form.phoneOrEmail,
          verificationCode: form.captcha,
        };
      }
      const res = await this.$axios.post(oneApi.sendLoginMobileCodeCheck, {
        ...data,
        scene: 1,
      }, {
        rewritePostBody: true,
      });

      if (Number(res.code) !== 0) {
        this.$ElMessage({
          type: 'error',
          message: res.msg,
        });
        this.isLoging = false
        return
      }
      // 中途验证
      if (isAccountLogin) {
        // 判断原账户信息是否为空后 执行逻辑
        if (form?.originAccountIsempty) {
          this.step = 3
          this.isLoging = false
        } else {
          this.login()
        }
        return
      }
      this.preCheckAccount()
    },

    // 信息预校验
    async preCheckAccount() {
      let data = {};
      if (this.type === 'phone') {
        data = {
          phone: this.loginInfo.phoneOrEmail,
          verificationCode: this.loginInfo.captcha,
          countryCallingCode: this.loginInfo.phoneAreaCode.value,
        };
      } else {
        data = {
          email: this.loginInfo.phoneOrEmail,
          verificationCode: this.loginInfo.captcha,
        };
      }
      const res = await this.$axios.post(oneApi.preCheck, {
        ...data,
      }, {
        rewritePostBody: true,
      });

      console.log('预校验', res.data);
      if (Number(res.code) !== 0) {
        this.$ElMessage({
          type: 'error',
          message: res.msg,
        });
        this.isLoging = false
        return
      }

      if (this.isTop === 2) {
        if (res.data?.exist) {
          this.isUnSignUp = true
          this.isLoging = false
        } else if (res.data?.related?.length > 0) {
          this.relateList = res.data.related
          this.step = 2
          this.isLoging = false
        } else {
          this.login()
        }
      } else if (this.isTop === 3) {
        if (res.data?.exist) {
          this.login()
        } else if (res.data?.related?.length > 0) {
          this.relateList = res.data.related
          this.step = 2
          this.isLoging = false
        } else {
          this.isUnSignUp = true
          this.isLoging = false
        }
      }
    },
    // 登录过程中 未选择已有账户 创建新账户登录
    createAccountLogin(formData) {
      console.log('createAccountLogin', formData);
      this.loginInfo = formData
      this.login(true)
    },
    /**
     * 登录
     */
    async login(needLoading = false) {
      this.isUnSignUp = false
      // 埋点
      if (this.isTop == 3) {
        this.Sensors.track('sign_in_click', {
          account_type: getLoginAccountType(this.type === 'phone' ? 0 : 3),
          previous_source: '弹窗',
          register_type: getLoginMethod(1),
        });
      } else {
        this.Sensors.track('sign_up_click', {
          account_type: getLoginAccountType(this.type === 'phone' ? 0 : 3),
          previous_source: '弹窗',
          register_type: getLoginMethod(1),
        });
      }
      if (!this.policyAgreeStatus && !this.isOrgSchool && this.isTop !== 3) {
        this.legalCount += 1;
        return;
      }
      if (needLoading) {
        this.isLoging = true;
      }
      let data = {};
      let lastModified = {};
      if (this.type === 'phone') {
        data = {
          type: 0,
          accountName: this.loginInfo.phoneOrEmail,
          verificationCode: this.loginInfo.captcha,
          countryCallingCode: this.loginInfo.phoneAreaCode.value,
        };
        lastModified = {
          type: 'phone',
          countryCallingCode: this.loginInfo.phoneAreaCode.value,
          accountName: this.loginInfo.phoneOrEmail,
        };
      } else {
        data = {
          type: 2,
          accountName: this.loginInfo.phoneOrEmail,
          verificationCode: this.loginInfo.captcha,
        };
        lastModified = {
          type: 'email',
          accountName: this.loginInfo.phoneOrEmail,
        };
      }
      console.log('this.isGroup', this.isGroup);
      const sourceObj = this.isGroup
        ? {
          sourceCode: 'group_purchasing',
          mediaCode: 'group_purchasing',
        }
        : {
          sourceCode: 'website',
          mediaCode: 'pc_signup',
        };
      // 验证码提交埋点
      this.Sensors.track('code_submit', {
        account_type: getLoginAccountType(this.type === 'phone' ? 0 : 3),
        type: this.isTop,
      });
      const res = await this.$axios.post(
        oneApi.commonLogin,
        {
          ...data,
          ...sourceObj,
        },
        { rewritePostBody: true },
      );

      if (Number(res.code) !== 0) {
        this.isLoging = false;

        this.$ElMessage({
          type: 'error',
          message: res.msg,
          offset: 80,
        });

        return;
      }

      this.isLoging = false;
      localStorage.setItem('lastModified', JSON.stringify(lastModified));
      const resData = res.data || {};
      this.$store.commit('ucenter/SET_IS_NEW_USER', resData.newUser);

      // 注册/登录成功埋点
      this.Sensors.track('sign_success', {
        account_type: getLoginAccountType(this.type === 'phone' ? 0 : 3),
        type: resData.newUser,
      });
      this.loginSuccessHandler(resData);
    },
    /**
     * 登录成功处理
     */
    async loginSuccessHandler(resData) {
      const accessToken = resData.unifiedAccessToken || '';

      if (!accessToken) {
        this.$ElMessage({
          type: 'error',
          message: this.$t('login.login.loginFailed'),
          offset: 80,
        });
        return;
      }

      // 设置Cookie
      setCookies('_official_token', accessToken);
      this.$store.commit('ucenter/SET_IS_LOGIN', true);

      // 更新用户信息
      await this.$store.dispatch('ucenter/queryUserBasicInfo', {
        $axios: this.$axios,
        userData: { fillInAttention: false },
        externalUserId: window.sessionStorage.getItem('external_user_id') || '',
        corpId: window.sessionStorage.getItem('corp_id') || '',
      });

      window.dataLayer.push({
        event: 'SignUp',
        isTop: this.isTop,
        newUser: resData.newUser,
      });
      // 渠道回传上报
      dataReport.launchReport({
        fbqOptions: {
          name: 'CompleteRegistration',
        },
        ttqOptions: {
          name: 'CompleteRegistration',
        },
      });
      if (this.locale === 'tm') {
        this.mediagoTrack();
      }

      // 登录结束，关闭弹窗
      this.$emit('close-dialog');

      if (this.diagnosis) {
        bus.$emit('toShowDiagnosis');
        this.diagnosis = false;
      }

      // 续报提醒
      // 续报提醒
      await this.getUnpaidList();
      const storage = new Storage();
      console.log(!storage.getItem('first_unpaid_remind'), this.hasUnpaidList);
      if (!storage.getItem('first_unpaid_remind') && this.hasUnpaidList) {
        bus.$emit('openUnpaidDialog');
        storage.setItem({
          name: 'first_unpaid_remind',
          value: true,
          expires: 259200000, // 3 days
        });
        return;
      }

      // 购课触发登录
      if (this.triggerSrc) {
        if (this.triggerSrc === 'BUY_BOOK') {
          bus.$emit('goods.detail.buy');
        }
        if (this.triggerSrc === 'REGISTRATION_COURSE') {
          bus.$emit('courses.detail.registrationCourses', true);
        }
        if (this.triggerSrc === 'FOLLOW_COURSE') {
          bus.$emit('courses.detail.registrationCourses', false);
        }
        return;
      }
      // 登录成功后跳转路径
      if (this.successUrl) {
        this.$router.push(this.successUrl);
      }
      const uid = this.$store.state.ucenter.user?.uid
      if (uid) {
        this.$store.dispatch('cart/getCartCount', { $axios: this.$axios, studentId: uid })
      }
    },
    async dsfLogin(type) {
      if (!this.policyAgreeStatus && !this.isOrgSchool) {
        this.legalCount += 1;
        return;
      }
      switch (type) {
        case 'f':
          console.log('脸书点击了');
          if (this.isTop == 3) {
            this.Sensors.track('sign_in_click', {
              account_type: 'Facebook',
              previous_source: '弹窗',
              register_type: '第三方',
            });
          } else {
            this.Sensors.track('sign_up_click', {
              account_type: 'Facebook',
              previous_source: '弹窗',
              register_type: '第三方',
            });
          }
          this.faceBookLogin();
          break;
        case 'a':
          console.log('苹果点击了');
          if (this.isTop == 3) {
            this.Sensors.track('sign_in_click', {
              account_type: 'Apple',
              previous_source: '弹窗',
              register_type: '第三方',
            });
          } else {
            this.Sensors.track('sign_up_click', {
              account_type: 'Apple',
              previous_source: '弹窗',
              register_type: '第三方',
            });
          }
          await this.appleLogin();
          break;
      }
    },
    faceBookLogin() {
      FB.login(
        (respones) => {
          this.lxToken(4, respones.authResponse.accessToken);
          console.log('脸书回调', respones);
        },
        {
          scope: 'public_profile, email',
        },
      );
    },
    async appleLogin() {
      try {
        const data = await AppleID.auth.signIn();
        // Handle successful response.
      } catch (error) {
        // Handle error.
      }
    },
    createAccount() {
      if (!this.policyAgreeStatus && !this.isOrgSchool && this.isTop !== 3) {
        this.legalCount += 1;
        return;
      }
      this.$emit('starsChange', 2);
      this.isTop = 2;
    },
    async lxToken(type, token) {
      const params = {
        unifiedTempToken: token,
        source: type,
      };
      console.log('这是临时token,', token);
      const res = await this.$axios.post(
        oneApi.getLsToken,
        { ...params },
        { rewritePostBody: true },
      );

      if (Number(res.code) !== 0) {
        this.$ElMessage.error(res.msg);
        return;
      }
      const resData = res.data || {};
      if (!resData.uid) return
      // 注册/登录成功埋点
      let typeText = ''
      if (type === 2) {
        typeText = 'Google'
      }
      if (type === 3) {
        typeText = 'Apple'
      }
      if (type === 4) {
        typeText = 'Facebook'
      }
      // 注册/登录成功埋点
      this.Sensors.track('sign_success', {
        account_type: typeText,
        type: resData.newUser,
      });
      this.loginSuccessHandler(resData);
    },
    toSignIn(num) {
      if (num == 1 || num == 3) {
        this.eventTrack('register_page_pv', { type: num == 1 ? 'sign-up' : 'sign-in' });
      }
      this.$emit('starsChange', num);
      this.isTop = num;
      this.isUnSignUp = false;
    },
  },
};
</script>
<style lang="scss">
.login-form-new-container {
  height: 269px;

  // .new-login-font {
  //   font-family: 'Montserrat-Regular', Montserrat, SFProRounded, 'SF Pro Rounded', 'SFPro-Semibold', Helvetica, Roboto, Arial, serif;

  //   .el-input__inner {
  //     font-family: 'Montserrat-Regular', Montserrat, SFProRounded, 'SF Pro Rounded', 'SFPro-Semibold', Helvetica, Roboto, Arial, serif;
  //   }
  // }

  .login-new-form {
    width: 100%;
    margin: 0;
  }

  .login-account-box {
    padding-top: 20px;

    .label-line {
      height: 18px;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 18px;
      color: var(--txt-color-lv2);
      font-weight: 400;
      display: flex;
      align-items: center;

      .iconfont {
        font-size: 18px;
        margin-right: 4px;
      }
    }
  }

  .code-input {
    position: relative;

    .el-input__inner {
      padding-right: 80px;
    }

    .el-input-group__append {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      height: 50px;
      padding: 0 10px;
      border: 0;
      background: transparent;
    }
  }

  .login-input {
    height: 50px;
    border-radius: 4px;
    display: block;

    .el-input__inner {
      height: 50px;
      padding: 14px 8px;
      background: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      color: var(--txt-color-lv1);
      font-size: 16px;
      font-weight: 400;

      &:focus {
        border-color: var(--txt-color-link);
      }
    }

    &.is-active .el-input__inner {
      border-color: var(--txt-color-link);
    }

    .el-input__suffix {
      .el-input__suffix-inner {
        .el-input__clear {
          position: absolute;
          top: 13px;
          right: 10px;
          width: 24px;
          height: 24px;
          background: url("~assets/web/images/login/hover-close-icon@2.png")
            no-repeat;
          background-size: 100%;

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }

  // 手机号登入
  .account-input.phone-box {
    position: relative;

    .el-input__inner {
      padding-left: 75px;
      border-radius: 4px;
    }

    .el-input-group__prepend {
      position: absolute;
      top: 0;
      left: 0;
      width: 67px;
      height: 100%;
      padding: 0 10px !important;
      background: transparent !important;
      border-radius: 0;
      border-color: transparent;

      .el-select {
        margin: 0;

        .el-input__inner {
          border: 0;
        }
      }
    }
  }
  .account-input.is-email-box {
    .el-input__inner {
      padding-left: 12px;
    }
    .el-input-group__prepend {
      display: none;
    }
  }

  .update-button-container {
    text-align: center;

    .switch-button {
      width: 100%;
      height: 50px;
      border-radius: 25px;
      border: 1px solid #e6e6e6;
      position: relative;
      margin: auto;
      color: var(--txt-color-lv2);
      line-height: 18px;

      &:hover {
        border-color: var(--txt-color-link) !important;
        background: var(--txt-color-link3);
        color: var(--txt-color-link);
      }

      .iconfont {
        font-size: 18px;
        margin-right: 6px;
      }

      &[disabled] {
        border-color: #e6e6e6;
        background: transparent;
      }
    }

    .login-button {
      position: relative;
      width: 100%;
      height: 60px;
      background: var(--txt-color-link);
      border-radius: 30px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      line-height: 60px;
      overflow: hidden;

      span.normal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 17;
        text-align: center;
      }

      &.is-disabled {
        background: var(--txt-color-link);

        &::after {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          content: "";
          display: block;
          background: rgba(255, 255, 255, 0.8);
        }
      }

      &:not(.is-disabled):hover {
        background: var(--txt-color-link2);

        &::after {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          content: "";
          display: block;
          background: rgba(0, 0, 0, 0.1);
        }

        span.hover {
          display: block;
        }
      }
    }
  }

  // 账号横线
  .update-divider {
    position: relative;
    padding: 40px 30px;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 139px;
      height: 0;
      border-bottom: 1px solid #e6e6e6;
      position: absolute;
      left: 30px;
      top: 46px;
    }

    &::after {
      left: unset;
      right: 30px;
    }

    .update-dividet-text {
      height: 12px;
      font-size: 12px;
      line-height: 1;
      font-weight: 400;
      color: var(--txt-color-lv2);
      text-align: center;
    }
  }

  // 验证码
  .verify-headline {
    .login-headline {
      // height: 24px;
      // margin-bottom: 10px;
      font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px;
      color: var(--txt-color-lv1);
    }

    .login-subtitle,
    .login-tip {
      height: auto;
      margin-bottom: 4px;
      font-size: 12px;
      font-weight: 400;
      color: var(--txt-color-lv2);
      line-height: 15px;
    }

    .login-tip {
      margin-bottom: 20px;
      color: #3370ff;
    }

    .login-exchange {
      margin-bottom: 4px;
    }

    .login-exchange-end {
      height: auto;
      margin-bottom: 20px;
      font-size: 12px;
      font-weight: 400;
      color: var(--txt-color-lv2);
      line-height: 15px;
    }
    .count {
      color: var(--txt-color-link)
    }
  }

  // 法律条文
  .login-policy-checkbox-container {
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 24px;
  }
}

.login-am,
.login-ae {
  .login-input {
    .el-input__inner {
      &:focus {
        border-color: #2cc0b7;
      }
    }

    &.is-active .el-input__inner {
      border-color: #2cc0b7;
    }
  }

  .update-button-container {
    .switch-button {
      &:hover {
        border-color: #2cc0b7 !important;
        background: #fff;
        color: #2cc0b7;
      }
    }

    .login-button {
      background: #2cc0b7;

      &.is-disabled {
        background: #2cc0b7;
      }

      &:not(.is-disabled):hover {
        background: #2cc0b7;
      }
    }
  }
}

.login-new-title {
  font-size: 20px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: bold;
  color: #222222;
  line-height: 24px;
}
.dsf-l {
  font-size: 14px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #222222;
  line-height: 20px;
  margin-top: 24px;
}
.dsf-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .s {
    width: 120px;
    height: 45px;
    background: #ffffff;
    border-radius: 23px;
    border: 1px solid #e6e6e6;
    overflow: hidden;
    cursor: pointer;
    #g_id_onload {
      width: 100%;
      height: 100%;
      pointer-events: all;
      opacity: 0.01;
    }

    &.s1 {
      background: url("https://download-pa-s3.thethinkacademy.com/message/picture/8601/20240612/1718182255109UzDEXefIdQ.png")
        center center/ 20px auto no-repeat;
    }
    &.s2 {
      position: relative;
      pointer-events: none;
      background: url("https://download-pa-s3.thethinkacademy.com/message/picture/8601/20240612/1718182301502xcwDwZirP7.png")
        center center/ 20px auto no-repeat;
      .pos-btn {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        pointer-events: all;
      }
      .cgd {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
    }
    &.s3 {
      background: url("https://download-pa-s3.thethinkacademy.com/message/picture/8601/20240612/1718182406795brCqVgAOXS.png")
        center center/ 20px auto no-repeat;
    }
  }
}
.line-or {
  font-size: 12px;
  font-family: Montserrat-Regular, Montserrat;
  font-weight: 400;
  color: var(--txt-color-lv2);
  line-height: 12px;
  height: 12px;
  position: relative;
  text-align: center;
  margin-top: 24px;
  &::after,
  &::before {
    width: 169px;
    height: 1px;
    background: #e6e6e6;
    content: "";
    position: absolute;
    top: 6px;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
}
.weixin_img{
  width: 250px;
  margin: 0 auto;
  // margin-top: 26px;
  // margin-bottom: 20px;
  img{
    display: block;
    width: 100%;
  }
}
.btn-to-nomorl {
  width: 392px;
  height: 60px;
  background: var(--txt-color-link);
  border-radius: 30px;
  font-size: 16px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: bold;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
  &:hover {
    background: var(--txt-color-link2);
  }
}
.sign-in-n,
.sign-up-n {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: var(--txt-color-lv2);
  line-height: 18px;
  margin-top: 20px;
  cursor: pointer;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
</style>
